import React, {useState} from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { renderTextField  } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import { ProconDeviceHistoryList } from './ProconDeviceHistoryList';

const formName = 'Dispositivos';
const modelName = 'proconjp/devices';
const label = 'Dispositivos';

const validate = values => {
    const errors = {}
    var requiredFields = [
        'machineName',
        'mac'
    ]

    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    return errors
}

let Form = (props) => {
    const upper = value => value && value.toUpperCase()
    const [id, setId] = useState('');

    const afterLoadEntity = (entity) => {
        setId(entity.id);
    }    

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            content={
                <Grid container spacing={2} wrap="wrap">
                    <Grid item xs={12}>
                        <Field name="machineName" type="text" style={{width: '100%'}} component={renderTextField} label="Nome" normalize={upper} {...{ required: true }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field label="Latitude" name="latitude" style={{width: '100%'}} component={renderTextField} {...{ required: true }}/>
                    </Grid>

                    <Grid item xs={6}>
                        <Field label="Longitude" name="longitude" style={{width: '100%'}} component={renderTextField} {...{ required: true }}/>
                    </Grid>
                    
                    <Grid item xs={6}>
                        <Field label="Situação" name="status" style={{width: '100%'}} component={renderTextField} {...{ disabled: true }}/> 
                    </Grid>  

                    <Grid item xs={12}>
                        <Field label="MAC" name="mac" style={{width: '100%'}} component={renderTextField} normalize={upper} {...{ required: true }}/>
                    </Grid>                    

                    <Grid item xs={12}>
                        <Field label="Cadastro em:" name="createdAt" style={{width: '100%'}} component={renderTextField} {...{ disabled: true }}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Field label="Última atualização em:" name="updatedAt" style={{width: '100%'}} component={renderTextField} {...{ disabled: true }}/>
                    </Grid>

                    <Grid item xs={12}>
                        { id && <ProconDeviceHistoryList id={id} style={{height: 300}} /> }
                    </Grid>

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
})(Form);

export default Form;

