import React, { useState } from "react"
import { Card, 
         CardHeader, 
         CardMedia, 
         CardContent, 
         Avatar, 
         IconButton, 
         Typography, 
         Popover,
         Grid } from "@material-ui/core";
import {MoreVert} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import CardActions from "./CardActions";
import api from '../../services/api';

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        color: theme.palette.info.light,
        whiteSpace: 'pre-line'
    }
}))

const CardGrid = ({ row, 
                    columns, 
                    route, 
                    endpoint, 
                    onRemoveItem, 
                    hideAddButton, 
                    hideEditButton, 
                    hideDeleteButton
                    }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [photoUrl, setPhotoUrl] = useState('');
    const [loading, setLoading] = useState(false);

    const handleOpenActions = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseActions = () => {
        setAnchorEl(null);
    };

    const getPhotoUrl = ()  => {

        
        if (!loading) {
            setLoading(true);
            if ((columns.find((c) => c.avatar )) && (avatarName === 'cpf')) {
                api.get(`/users/photourl/${handleName(row, avatarName)}`)
                .then((response) => {
                    const preSignedURL = response.data.url
                    setPhotoUrl(preSignedURL);
        
                })
                .catch((error) => {
                    console.error(error);            
                    // ToastMessage.error('Problema ao atualizar Perfil');
                })
            }

        }
    }    

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const primaryName = columns && columns.find((c) => c.primary) && columns.find((c) => c.primary).name
    const secondaryName = columns && columns.find((c) => c.secondary) && columns.find((c) => c.secondary).name
    const avatarName = columns && columns.find((c) => c.avatar) && columns.find((c) => c.avatar).name
    const pictureName = columns && columns.find((c) => c.picture) && columns.find((c) => c.picture).name
    const contentName = columns && columns.find((c) => c.content) && columns.find((c) => c.content).name

    const handleName = (row, name) => {
        if (!name) return undefined;
        
        if (name.indexOf("$") !== -1) {
            name = name.replaceAll('$', '')
        }

        if (name.indexOf('.') !== -1) {
            const nameTokens = name.split('.')
            if (nameTokens.length === 1) {
                return row[nameTokens[0]]
            } else if (nameTokens.length === 2) {
                return row[nameTokens[0]][nameTokens[1]]
            } else if (nameTokens.length === 3) {
                return row[nameTokens[0]][nameTokens[1]][nameTokens[2]]
            } else if (nameTokens.length === 4) {
                return row[nameTokens[0]][nameTokens[1]][nameTokens[2]][nameTokens[3]]
            }
        }

        return row[name];
    }

    const handlePrimaryNameAvatar = (row, name) => {
        const pName = handleName(row, name)

        return pName && pName[0]
    }

    getPhotoUrl();


    return (

        <Grid container spacing={3} wrap="wrap">
        <Grid item xs={12} >

            <Card style={{margin: 5,  maxWidth: '100%', maxHeight: "100%"}} elevation={2}>
                <CardHeader
                    style={{ maxWidth: '100%', width: 400 }}
                    avatar={
                        <Avatar 
                            className={classes.avatar} 
                            aria-label="recipe"
                            src={columns.find((c) => c.avatar )?photoUrl:handlePrimaryNameAvatar(row, primaryName)}
                            >                            
                            {/* {primaryName[1]} */}
                            {handlePrimaryNameAvatar(row, primaryName)} 
                        </Avatar>
                    }
                    action={
                    <IconButton aria-label="settings" onClick={handleOpenActions}>
                        <MoreVert/>
                    </IconButton>
                    }
                    title={handleName(row, primaryName)}
                    subheader={handleName(row, secondaryName)}
                />

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseActions}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <CardActions route={route} endpoint={endpoint} row={row} onRemoveItem={onRemoveItem} hideAddButton={hideAddButton} hideEditButton={hideEditButton} hideDeleteButton={hideDeleteButton}/>
                </Popover>

                {pictureName && <CardMedia
                    component="img"
                    height="100%"
                    image={handleName(row, pictureName)}
                    alt={handleName(row, pictureName)}
                />}

                {contentName &&
                    <CardContent>
                        <Typography variant="body2" className={classes.content}>
                            {handleName(row, contentName)}
                        </Typography>
                    </CardContent>
                }
            </Card>
        </Grid>
        </Grid>
    )
}

export default CardGrid;