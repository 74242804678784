import React from 'react';
import { isMobile } from 'react-device-detect';
import CardList from '../../../components/CardList/CardList';
import { GridList } from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const UserList = (props) => {
    const columns = [
        // { name: 'id', title: '#' },
        { name: 'cpf', title: 'CPF', width: 150, filterable: true, secondary: true },
        { name: 'name', title: 'Nome', filterable: true, primary: true },
        { name: 'status', title: 'Situação', width:100 },
        { name: 'email', title: 'e-mail', filterable: true, content: true },
        { name: 'cpf', title: 'CPF', width: 1 , filterable: false, avatar: true },
    ]

    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid>
                <Grid item>
                    <Card className='cardPersonalData'>
                    <CardHeader title="Usuários" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {/* <CardList route={"users"} endpoint={"users"} columns={columns} /> */}
                            
                            {isMobile ?
                            <CardList route={"users"} endpoint={"users"} columns={columns}/> :
                            <GridList modelName={"Usuário"} 
                                editModelRoute={'/users'}
                                newModelRoute={'/users/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'users'}
                                enableActions={true}
                                columns={columns}/>}
                        </Grid> 
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

