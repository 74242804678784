import React, {useState, } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, CardContent, CardHeader, Card, Avatar, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { renderLabelField } from '../../../../../components/Fields/Fields';
import BasicForm from '../../../../../components/BasicForm';
import api from '../../../../../services/api';
import LOCAL_URL from '../../../../../services/qrCodeConfig';
import QRCode from "react-qr-code";
import Header from "./CARTA_FRENTE_PREFEITURA.png";

import './required-field.css';

const formName = 'Carteira';
const modelName = 'public/usuario/cracha';
const label = 'Carteira';

const useStyles = makeStyles(theme => ({
    root: {
        margin: "auto",
        textAlign: "center",
        maxWidth: "100%",
    },
    rootCart: {
        position: "relative",
        margin: "auto",
        textAlign: "center",
        maxWidth: "100%",
        width:"400",
        height:"400"
    },    
    content: {
        paddingTop: 150,
        textAlign: 'center'
    },

}));
  
let Form = (props) => {
    const classes = useStyles();
    const upper = value => value && value.toUpperCase()
    const [photoUrl, setPhotoUrl] = useState("");
    const [entity, setEntity] = useState({});
    
    const afterLoadEntity = (entity) => {
        setEntity(entity);
        getPhotoUrl(entity);

    }
        
    const getPhotoUrl = (entity)  => {        
        api.get(`public/usuario/photourl/${entity.fotoid}`)
        .then((response) => {
            const preSignedURL = response.data.url
            setPhotoUrl(preSignedURL);

        })
        .catch((error) => {
            console.error(error);
            // setUploading(false);
            // ToastMessage.error('Problema ao atualizar Perfil');
        })
    }    

    return (
        <div className={classes.root}>
            <Grid container justify="center" spacing={12} >
                <Grid item lg={12} xs={12}>
                    <Grid>
                        <BasicForm
                            // routeAfterSubmit="/minhahabitacao/sucesso"
                            customAfterLoadEntity={afterLoadEntity}
                            needRecaptch={false}
                            hiddenNewButton={true}
                            hiddenSaveButton={true}
                            content={
                                <Grid container justify="center" className="row">
                                    <Card className="personalData">

                                        <CardHeader title="Aluno"/>
                                        <CardContent img>
                                            <Grid container spacing={2} justify="center">
                                                <CardMedia 
                                                    className={classes.rootCart}                                        
                                                    component="img"
                                                    alt="Foto"
                                                    image={Header}
                                                    title="Foto"
                                                />                                               
                                                <Avatar
                                                    alt="Person"
                                                    variant="square"                            
                                                    style={{ position: "absolute", top:320, left:72, width: 230, height: 270}}
                                                    src={photoUrl || "/images/avatars/generic-user.png"}
                                                />                        

                                                <Field 
                                                    name="name" 
                                                    type="text" 
                                                    component={renderLabelField} 
                                                    label="Nome" 
                                                    normalize={upper} 
                                                    style={{ fontStyle:'weight', position: "absolute", top:320, left:330, backgroundColor:'#ffffff8b',  width:420  }} 
                                                     />

                                                <Field 
                                                    name="secretaria" 
                                                    type="text" 
                                                    component={renderLabelField} 
                                                    label="Instituição de Ensino" 
                                                    normalize={upper}                                                     
                                                    style={{ fontStyle:'weight', position: "absolute", top:385, left:330, backgroundColor:'#ffffff8b',  width:420  }} 
                                                    {...{ required: true }}/>

                                                <Field 
                                                    name="cargo" 
                                                    type="text" 
                                                    component={renderLabelField} 
                                                    label="Curso" 
                                                    normalize={upper} 
                                                    style={{ fontStyle:'weight', position: "absolute", top:450, left:330, backgroundColor:'#ffffff8b',  width:420 }} 
                                                    />

                                                <Field 
                                                    name="rg" 
                                                    type="text" 
                                                    component={renderLabelField} 
                                                    label="Matricula" 
                                                    normalize={upper} 
                                                    maxLength={11} 
                                                    style={{ fontStyle:'weight', position: "absolute", top:515, left:330, backgroundColor:'#ffffff8b', width:100 }} 
                                                    />

                                                <Field 
                                                    name="birthDate" 
                                                    type="date"
                                                    component={renderLabelField} 
                                                    label="Data Nasc." 
                                                    normalize={upper} 
                                                    style={{ fontStyle:'weight', position: "absolute", top:515, left:440, backgroundColor:'#ffffff8b', width:150 }} 
                                                    />
                                                
                                                <Field 
                                                    name="fotoid" 
                                                    type="text"
                                                    component={renderLabelField} 
                                                    label="CPF" 
                                                    normalize={upper} 
                                                    style={{ fontStyle:'weight', position: "absolute", top:515, left:600, backgroundColor:'#ffffff8b', width:150 }} 
                                                    />

                                                    <QRCode                                                        
                                                        style={{ width: 100,height: 100,  position: "absolute", top:635, left:130,  }} 
                                                        bgColor='#f5f5f5'
                                                        fgColor='#000000'
                                                        size={100}
                                                        value={LOCAL_URL+"/crachavirtual/" + entity.id}
                                                    />                                                      
                                                
                                                {/* <Grid item xs={12}>
                                                    <Field name="name" type="text" component={renderLabelField} label="Nome" normalize={upper} style={{ maxWidth: '100%', width: '95%', color:'red' }} {...{ required: true }} />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Field name="rg" type="text" component={renderLabelField} label="Matricula" normalize={upper} maxLength={11} style={{maxWidth: '100%', width: '95%'}} {...{ required: true }}/>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Field name="secretaria" type="text" component={renderLabelField} label="Setor" normalize={upper} maxLength={11} style={{maxWidth: '100%', width: '95%'}} {...{ required: true }}/>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Field name="cargo" type="text" component={renderLabelField} label="Cargo" normalize={upper} maxLength={11} style={{maxWidth: '100%', width: '95%'}} {...{ required: true }}/>
                                                </Grid>                                                

                                                <Grid item xs={12} >
                                                    <h2>Código de Verificação</h2>
                                                    <QRCode                                                        
                                                        style={{ width: 150,height: 150, maxWidth: '100%',maxHeight: '100%', marginTop:20 }} 
                                                        bgColor='#f5f5f5'
                                                        fgColor='#000000'
                                                        size={150}
                                                        value={LOCAL_URL+"/crachavirtual/" + entity.id}
                                                    />                        
                                                </Grid>                                                   */}

                                            </Grid>
                                        </CardContent>
                                    </Card>

                                </Grid>
                            }
                            formName={formName}
                            modelName={modelName}
                            label={label}
                            { ...props }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

Form = reduxForm({
    form: formName,
    initialValues:{}
})(Form);

export default Form;

