import React, {useState} from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormControl, Grid, TextField } from '@material-ui/core';
import { renderTextField, renderTextAreaField, renderSelectField  } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import { validCpf } from '../../../services';
import { DateMaskDDMMYYYY, phoneMask } from '../../../services/masks';
import InputLabel from '@material-ui/core/InputLabel';

const formName = 'Atendimento';
const modelName = 'proconjp/atendimentos';
const label = 'Atendimentos';

const validate = values => {
    const errors = {}
    var requiredFields = [
        'nome',
        'cpf',
        'telefone'
    ]

    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    // VALIDAÇAO EMAIL
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors['email'] = 'Endereço de Email invalido'
    }
    
    // VALIDAÇAO CPF
    if (values.cpf && !validCpf(values.cpf)) {
        errors['cpf'] = 'Cpf inválido';
    }

    return errors
}

let Form = (props) => {
    const [dateStart, setDateStart] = useState('');
    const [timeStart, setTimeStart] = useState('');
    const [timeWithMillis, setTimeWithMillis] = useState('');

    const upper = value => value && value.toUpperCase()

    const afterLoadEntity = (entity) => {
        setDateStart(entity.startedAt.split("T")[0]);
        setTimeWithMillis(entity.startedAt.split("T")[1].split(".")[1]);
        setTimeStart(entity.startedAt.split("T")[1].split(".")[0]);
    }

    const beforeSubmit = (values) => {
        values.startedAt = dateStart + "T" + timeStart + "." + timeWithMillis;
    }

    const changeDateStart = ({target}) => {
        setDateStart(target.value)
    }

    const changeTimeStart = ({target}) => {
        setTimeStart(target.value)
    }

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            customBeforeSubmit={beforeSubmit}
            content={
                <Grid container spacing={2} wrap="wrap">
                    <Grid item xs={12}>
                        <Field name="nome" type="text" style={{width: '100%'}} component={renderTextField} label="Nome" normalize={upper} {...{ required: true }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field label="Telefone" name="telefone" style={{width: '100%'}} component={renderTextField} {...phoneMask} {...{ required: true }}/>
                    </Grid>

                    
                    <Grid item xs={6}>
                        <Field  id="status" name="status" component={renderSelectField} label="Situação" style={{ maxWidth: '100%', width: 300 }}>
                            <option value="" />
                            <option value={'A'}>ATENDIMENTO CONCLUIDO</option>
                            <option value={'I'}>INATIVO</option>
                            <option value={'P'}>PENDENTE</option>
                        </Field>
                    </Grid>  

                    <Grid item xs={12}>
                        <Field label="Email" name="email" style={{width: '100%'}} component={renderTextField} {...{ required: true }}/>
                    </Grid>                    

                    <Grid item xs={12}>
                        <Field label="Comentário" name="comentario" style={{width: '100%'}} component={renderTextAreaField} {...{ required: true }}/>
                    </Grid>

                    {/* Implementação manual dos campos Data e Hora, pois são composição do campo startDate */}
                    <Grid item xs={6}>
                        <InputLabel>Data Atendimento</InputLabel>
                        <FormControl style={{maxWidth:'100%'}} >
                            <TextField 
                                variant="outlined"
                                type="date"
                                value={dateStart}
                                style={{ maxWidth: '100%', width: 300 }}
                                onChange={changeDateStart}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel>Hora Atendimento</InputLabel>
                        <FormControl style={{maxWidth:'100%'}} >
                            <TextField 
                                variant="outlined"
                                type="time"
                                value={timeStart}
                                style={{ maxWidth: '100%', width: 300 }}
                                onChange={changeTimeStart}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
})(Form);

export default Form;

