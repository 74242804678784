import React from 'react';
import { isMobile } from 'react-device-detect';
import CardList from '../../../components/CardList/CardList';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const ExameList = (props) => {
    const columns = [
        { name: 'requisicao', title: 'Requisição', filterable: true, secondary: true },
        { name: 'paciente', title: 'Nome', filterable: true, primary: true },
        { name: 'status_exame', title: 'Situação' },
        { name: 'resultado', title: 'Resultado', filterable: true, content: true}
    ]

    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid>
                <Grid item>
                    <Card className='cardPersonalData'>
                    <CardHeader title="Exames" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ?
                            <CardList route={"exames"} endpoint={"exames"} columns={columns}/> :
                            <GridList modelName={"Exame"} 
                                editModelRoute={'/exames'}
                                newModelRoute={'/exames/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'exames'}
                                enableActions={true}
                                columns={columns}/>}
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

