import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { renderSelectField, renderSelectSearchField, renderTextAreaField, renderTextField  } from '../../../../../components/Fields/Fields';
import BasicForm from '../../../../../components/BasicForm';
import { validCpf } from '../../../../../services';
import { agenciaBancoMask, contaBancoMask, cpfMask, phoneMask } from '../../../../../services/masks';
import UploadFile from '../../../../../components/UploadFile';
import BankList from './BankList';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const formName = 'Responsável';
const modelName = 'proconjp/responsaveis';
const label = 'Responsáveis';

const validate = values => {
    const errors = {}
    var requiredFields = [
        'nome',
        'cpf',
        'telefone',
        'email',
        'endereco',
        'codigoBanco',
        'tipoConta',
        'agenciaBanco',
        'numeroConta'
    ]

    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    // VALIDAÇAO EMAIL
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors['email'] = 'Endereço de Email invalido'
    }
    
    // VALIDAÇAO CPF
    if (values.cpf && !validCpf(values.cpf)) {
        errors['cpf'] = 'Cpf inválido';
    }

    return errors
}

let Form = (props) => {
    const { id } = useParams();
    const [entity, setEntity] = useState({})
    const upper = value => value && value.toUpperCase()

    const afterLoadEntity = (entity) => {
        setEntity(entity);
    }

    const createList = () => {
        let list = []
        if (BankList && BankList.length > 0) {
            BankList.forEach((bank) => {
                list.push({name: `${bank.codigo} - ${bank.nome}`, value: bank.codigo})
            })
        }
        return list;
    }

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            content={
                <Grid container spacing={2} wrap="wrap">
                    <Grid item xs={12}>
                        <Field name="nome" type="text" style={{width: '100%'}} component={renderTextField} label="Nome" normalize={upper} {...{ required: true }} />
                    </Grid>

                    <Grid item xs={6} >
                        <Field name="cpf" type="text" component={renderTextField} label="CPF" normalize={upper} style={{ width: '100%' }} maxLength={11} {...{disabled: entity.id, required: true }} {...cpfMask()} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field label="Telefone" name="telefone" style={{width: '100%'}} component={renderTextField} {...phoneMask} {...{ required: true }}/>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Field label="Email" name="email" style={{width: '100%'}} component={renderTextField} {...{ required: true }}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="endereco" type="text" component={renderTextAreaField} label="Endereço" normalize={upper} style={{ maxWidth: '100%', width: '100%' }} {...{ required: true }}/>
                    </Grid>

                    <Grid item xs={8}>
                        {
                            id ?
                            entity && entity.codigoBanco && 
                            <Field label="Banco" name="codigoBanco" style={{marginLeft: 10, width: 410}} component={renderSelectSearchField} {...{ required: true, options: createList(), defaultValue: entity.codigoBanco }} />
                            :
                            <Field label="Banco" name="codigoBanco" style={{marginLeft: 10, width: 410}} component={renderSelectSearchField} {...{ required: true, options: createList() }} />
                        }
                    </Grid>

                    <Grid item xs={4}>
                        <Field label="Tipo de Conta" style={{width: 205}} name="tipoConta" component={renderSelectField} {...{ required: true }}>
                            <option value={''}></option>
                            <option value={'P'}>POUPANÇA</option>
                            <option value={'C'}>CONTA CORRENTE</option>
                            <option value={'S'}>CONTA SALÁRIO</option>
                        </Field>
                    </Grid>

                    <Grid item xs={4}>
                        <Field name="agenciaBanco" type="text" component={renderTextField} label="Agência (com DV)" normalize={upper} style={{ width: '100%' }} {...agenciaBancoMask} {...{ required: true }}/>
                    </Grid>

                    <Grid item xs={8}>
                        <Field name="numeroConta" type="text" maxLength={10} component={renderTextField} label="Número Conta (com DV)" normalize={upper} style={{ width: '100%' }} {...contaBancoMask} {...{ required: true }}/>
                    </Grid>

                    {entity?.id && <Grid item xs={12}>
                        <UploadFile context="proconjpResponsaveis" label="Incluir Documento" entity={entity} list={entity.files}></UploadFile>
                    </Grid>}
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
})(Form);

export default Form;

