import axios from 'axios';
import { ToastMessage } from '../layouts';

const API_URL_NOTIFY = "@NOTIFICATION_URL@";
const API_KEY_ONESIGNAL = "21498576-1c88-408e-b9eb-c00a77de94d7";
const TOKEN_API_NOTIFY = 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJvd25lciI6Imhlc3RpYS1tb2JpbGUtYXBwIn0.Kg_9HNQa3sDG5Viv4WJW2m2u-JA64v6vjYb5ez7t53einnMdJ6t31k68UU6Ha-NYP03G-DUiwg89DcKrvNVJYg';



const apiNotify = axios.create({
    baseURL: API_URL_NOTIFY,
    headers:{ Authorization : `Bearer ${TOKEN_API_NOTIFY}` },   
  });


  apiNotify.interceptors.response.use(async response => {
    return response;
  }, async error => {
    if (400 === error.response.status) {
      const msg = error.response.data.error;
      ToastMessage.error(`Problemas no servidor: ${msg}`)
    } else if (401 === error.response.status) {
      ToastMessage.warning('Sessão Inválida');
    } else if (403 === error.response.status) {
      ToastMessage.warning('Você não tem permissão para acessar esse recurso');
    } else if (500 === error.response.status) {
      ToastMessage.error('Erro interno no servidor')
    }
    return Promise.reject(error);
  }
  );

  // apiNotify.interceptors.request.use(async (config) => {
  
  //   if (config.url === '/users') {
  //     config.baseURL = API_URL_NOTIFY;
  //     config.headers.Authorization = `Bearer ${TOKEN_API_NOTIFY}`;
  //   }    
  //   return config;
  // });

  export default apiNotify;