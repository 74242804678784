import React, {useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import AuthState from '../../../../../../auth/Auth';
import api from '../../../../../../services/api';

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        marginTop: theme.spacing(1)
    }
});

class Profile extends React.Component {
    state = {
        user: AuthState.getUserInfo(),
        profile: AuthState.getProfileInfo(),
        company: AuthState.getCompanyInfo(),
        photourl:''
    }

    useEffect = (() => {
        AuthState.getUserInfo().then( (luser) => {
            this.setState({user: luser})               
            this.getPhotoUrl(luser)
        })
    })

    getPhotoUrl(entity){

        api.get(`/users/photourl/${entity.cpf}`)
        .then((response) => {
            const preSignedURL = response.data.url            
            this.setState({photourl: preSignedURL})                    

        })
        .catch((error) => {
            console.error(error);
            // setUploading(false);
            // ToastMessage.error('Problema ao atualizar Perfil');
        })
    }

    render() {
        const { classes, rest, className } = this.props
        const {user, profile, company} = this.state;

        return (
            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
                <Avatar
                    alt="Person"
                    className={classes.avatar}
                    component={RouterLink}
                    src={ this.state.photourl || "/images/avatars/generic-user.png"}
                    to="/home"
                />
                <Typography
                    className={classes.name}
                    variant="h4"
                >
                    {user.name}
                </Typography>
                <Typography variant="body2">{profile.name} - {company}</Typography>
            </div>
        )
    }
}

Profile.propTypes = {
    className: PropTypes.string
};

export default withStyles(useStyles)(Profile)