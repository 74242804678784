import React, {useState} from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate } from '../../../services';
import { Grid } from '@material-ui/core';
import { renderTextField, renderTextAreaField, renderSelectField, renderDatePicker, } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import api from '../../../services/api';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { isMobile } from 'react-device-detect';

const formName = 'RegistroAtividadesHistorico';
const modelName = 'registroAtividadesHistorico';
const label = 'Registro de Atividade Historico';


let Form = (props) => {
    const [entity, setEntity] = useState({});
    const [loadingSecretaria, setLoadingSecretaria] = useState(false);
    const [secretariaList, setSecretariaList] = useState([]); 
    const [userList, setUserList] = useState([]);
    const [loadingUser, setLoadingUser] = useState(false);         

    const [loadingEscalaAtividades, setLoadingEscalaAtividades] = useState(false);
    const [escalaAtividadesList, setEscalaAtividadesList] = useState([]); 

    const [position, setPosition] = useState({});
    const [nome, setNome] = useState('');
    
    const [positionEmpresa, setPositionEmpresa] = useState({});
    const [nomeEmpresa, setNomeEmpresa] = useState('PMJP');

    const [center, setCenter] = useState({ lat: -7.1466036, lng: -34.9516383 });



    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={15}
            center={center}
            // defaultCenter={{ lat: -7.1466036, lng: -34.9516383 }}
        >
            {position && position.lat &&                 
                <Marker 
                    icon={{url:'/images/MarkerPerson.png' } }
                    // label={{fontWeight: "bold", text: nome.split(' ')[0], color:'#038d31'}} 
                    // options={{anchorPoint:{x:100,y:50}}}
                    title={nome}                    
                    position={position}  />   
            }
            {/* {console.log(positionEmpresa)} */}
            {positionEmpresa && positionEmpresa.lat && 
                <Marker 
                    icon={{url:'/images/MarkerEmpresa.png' }}  
                    // label={nomeEmpresa.split(' ')[0]}                     
                    position={positionEmpresa} 
                    title={nomeEmpresa.split(' ')[0]}
                    // position={{lat: -7.111588, lng: -34.850261}} 
                    
                    />
            }

        </GoogleMap>
    ))
    

    
    const getEscalaAtividadesList = () => {

        if (!loadingEscalaAtividades) {
            setLoadingEscalaAtividades(true);
            api.get('/escalasAtividades?pageSize=-1').then(list => {
                setEscalaAtividadesList(list.data.rows);                
            });
        }
    }   

    const getUserList = () => {
        if (!loadingUser) {
            setLoadingUser(true);
            api.get('/users?pageSize=-1').then(list => {
                setUserList(list.data.rows);
            });
        }
    }    

    const upper = value => value && value.toUpperCase()
    
    const afterLoadEntity = (entity) => {
        setEntity(entity);

        setPositionEmpresa({lat: parseFloat(entity.secretaria.latitudeMin) , lng: parseFloat(entity.secretaria.longetudeMin)});
        setNomeEmpresa(entity.secretaria.nome);
        setNome(entity.users.name.split(' ')[0] + ' | '+ entity.dataRegistro + ' as '+ entity.horaRegistro);
        setPosition({lat: parseFloat(entity.latitude), lng: parseFloat(entity.longitude)});
        setCenter({lat: parseFloat(entity.latitude), lng: parseFloat(entity.longitude)});
    }

    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.titulo||entity.nome||entity.name||entity.descricao}</option>);
            })
            return (finalList);
        }
    }  

    const getSecretariasList = () => {

        if (!loadingSecretaria) {
            setLoadingSecretaria(true);
            api.get('/secretarias?pageSize=-1').then(list => {
                setSecretariaList(list.data.rows);                
            });
        }
    }      
    
    getSecretariasList();    
    getUserList(); 
    getEscalaAtividadesList();   

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            imageContext={'registroAtividadesHistorico'}
            keepUpdateAfterSubmit={true}
            maxImages={2}
            hiddenRemoveImgButton={true}
            hiddenNewButton={true}
            hiddenSaveButton={true}

            // customAfterInitialState={afterInitialState}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12}>
                    <div >
    
                        <MyMapComponent
                            isMarkerShown
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmiTdTowI7DxNIodiBXI0d6JkCqOuGwA0&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{maxWidth: '100%', width: 1000 , height: `100%` }} />}
                            containerElement={<div style={{maxWidth: '100%', width: 1000 , height: `100%` }} />}
                            center={position}                            
                            mapElement={<div style={{ height: `300px`}} />}
                        />
                        </div>  
                    </Grid>                     

                    <Grid item xs={isMobile?12:6}>
                        <Field disabled={true} name="secretariaId" component={renderSelectField} label="Unidade de Lotação" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(secretariaList)}
                        </Field>
                    </Grid>  

                    <Grid item xs={isMobile?12:6}>
                        <Field disabled={true} name="escalasAtividadeId" component={renderSelectField} label="Escalas de Atividades" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(escalaAtividadesList)}
                        </Field>
                    </Grid>                      

                    <Grid item xs={12}>
                        <Field disabled={true} name="comentario" type="textarea" component={renderTextAreaField} label="Comentário" normalize={upper} style={{ maxWidth: '100%', width: 800 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field disabled={true} name="dataRegistro" type="date" component={renderDatePicker} label="Data Registro" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field disabled={true} name="horaRegistro" type="text" component={renderTextField} label="Hora Registro" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field disabled={true} label="status" name="status" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                            <option value={'P'}>Pendente</option>
                            <option value={'R'}>Registrado</option>
                            <option value={'C'}>Cancelado</option>
                        </Field>
                    </Grid>  
                    <Grid item xs={isMobile?12:6}>
                        <Field disabled={true} label="dia" name="dia" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                            <option value={'0'}>Domingo</option>
                            <option value={'1'}>Segunda</option>
                            <option value={'2'}>Terça</option>
                            <option value={'3'}>Quarta</option>
                            <option value={'4'}>Quinta</option>
                            <option value={'5'}>Sexta</option>
                            <option value={'6'}>Sábado</option>                            
                        </Field>
                    </Grid>    
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{
        status:'P'
    }
})(Form);

export default Form;

