import React from 'react';
import { isMobile } from 'react-device-detect';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import CardList from '../../../components/CardList/CardList';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const DemandasList = (props) => {
    const columns = [
        { name: 'protocolo', title: 'Protocolo', width: 80, filterable: true, content: true },
        { name: '$secretaria.nome$', title: 'Secretaria', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 130, filterable: true, primary: true },
        { name: 'cep', title: 'Cep' , width: 130, filterable: true, secondary: true},
        { name: 'situacao', title: 'situacao' , width: 130},
        { name: 'qualidade', title: 'Qualidade' , width: 130},
        { name: 'tipo', title: 'Tipo' , width: 130},
    ]

    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Demanda excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid>
                <Grid item>
                    <Card className='cardPersonalData'>
                    <CardHeader title="Demandas" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ?
                                <CardList route={"demandas"} endpoint={"demandas"} columns={columns}/> :
                                <GridList modelName={"Demanda"} 
                                    editModelRoute={'/demandas'}
                                    newModelRoute={'/demandas/new'}
                                    deleteCallback={deleteCallback}
                                    deleteCallbackFailure={deleteCallbackFailure}
                                    endpoint={'demandas'}
                                    enableActions={true}
                                    columns={columns}/>
                            }
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

