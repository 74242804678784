import React, {useState} from 'react';
import { Field, reduxForm} from 'redux-form';
import { validate } from '../../../services';
import { Grid } from '@material-ui/core';
import { renderTextField, renderSelectField, renderCheckbox} from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import api from '../../../services/api';
import { isMobile } from 'react-device-detect';

const formName = 'RegistroAtividadesCfg';
const modelName = 'registroAtividadesCfg';
const label = 'Configuração de Registro de Atividade';

let Form = (props) => {
    // const [entity, setEntity] = useState({});

    const upper = value => value && value.toUpperCase()
    const [loadingSecretaria, setLoadingSecretaria] = useState(false);
    const [secretariaList, setSecretariaList] = useState([]); 
    
    // const afterLoadEntity = (entity) => {
    //     setEntity(entity);
    // }

    // const afterInitialState = () => {
    //     setEntity({});
    // }
    const getSecretariasList = () => {

        if (!loadingSecretaria) {
            setLoadingSecretaria(true);
            api.get('/secretarias?pageSize=-1').then(list => {
                setSecretariaList(list.data.rows);                
            });
        }
    }  

    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.titulo||entity.nome||entity.name}</option>);
            })
            return (finalList);
        }
    }     

    getSecretariasList();

    return (
        <BasicForm
            // customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={false}
            // customAfterInitialState={afterInitialState}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12}>
                        <Field name="descricao" type="text" component={renderTextField} label="Descrição" normalize={upper} style={{ maxWidth: '100%', width: 700 }} />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field label="Tipo" name="tipo" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                            <option value={'P'}>Periódico</option>
                            <option value={'R'}>Randômico</option>
                        </Field>
                    </Grid>   

                    <Grid item xs={isMobile?12:6}>
                        <Field label="status" name="status" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                            <option value={'A'}>Ativo</option>
                            <option value={'I'}>Inativo</option>
                        </Field>
                    </Grid>                       

                    <Grid item xs={12}>
                        <Field name="secretariaId" component={renderSelectField} label="Unidade de Lotação" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(secretariaList)}
                        </Field>
                    </Grid> 

                    <Grid item xs={isMobile?12:6}>
                        <Field name="previsualizado" type="text" component={renderCheckbox} label="Pré-Visualizado" style={{ maxWidth: '100%', width: 100 }}  />
                    </Grid> 

                    <Grid item xs={isMobile?12:6}>
                        <Field name="isDefault" type="text" component={renderCheckbox} label="Config. Padrão" style={{ maxWidth: '100%', width: 100 }}  />
                    </Grid>                                          

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{
        'status':'A',
        'tipo':'P',
    }
})(Form);

export default Form;

