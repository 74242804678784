import React from 'react';
import { isMobile } from 'react-device-detect';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const CandidatoList = (props) => {
    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                //md={8}
                //xs={12}
                >
                    <Card className='cardPersonalData'>
                    <CardHeader title="Candidatos" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            <GridList modelName={"Candidato"} 
                                editModelRoute={'/candidatos'}
                                newModelRoute={'/candidatos/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'candidatos'}
                                enableActions={true}
                                columns={[
                                //{ name: 'id', title: '#' },
                                { name: '$cadastropessoal.cpf$', title: 'CPF', getCellValue: row => row.cadastropessoal ? row.cadastropessoal.cpf : null , width: 150},
                                { name: '$cadastropessoal.nome$', title: 'Nome', getCellValue: row => row.cadastropessoal ? row.cadastropessoal.nome : null , width: 200},
                                { name: 'status', title: 'Situação', width: 100 }
                            ]}/>
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

