import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { api } from '../../services';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Icon from '../../components/icon/icons';

export const ActionsContainer = (props) => {
    const modelUrl = props.url;
    const history = useHistory();
    const deleteCallback = props.deleteCallback;
    const deleteCallbackFailure = props.deleteCallbackFailure;
    const deleteUrl = props.deleteUrl;
    const modelName = props.modelName;
    const hiddenUpdateButton = props.hiddenUpdateButton || false;
    const hiddenDeleteButton = props.hiddenDeleteButton || false;

    const row = props.row;
    let deleting = false;

    if (row.dayofweek !== undefined && Number.isInteger(row.dayofweek)) {
        row.dayofweek = getDayFromInteger(row.dayofweek);
    }

    function getDayFromInteger(integerDay) {
        const days = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sabado"];
        return days[integerDay];
    }

    const viewClick = () => {
        const url = ('/' + modelUrl + '/' + row.id).replace('//', '/');
        history.push(url);
    }

    const deleteClick = () => {
        confirmAlert({
            title: 'Confirma exclusão',
            message: `Confirma exclusão do ${modelName}: ${row.name ? row.name : row.id}?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        deleting = true;
                        api.delete(`${deleteUrl}/${row.id}`)
                            .then(response => {
                                deleteCallback(response);
                                deleting = false;
                            })
                            .catch(error => {
                                deleteCallbackFailure(error);
                                deleting = false;
                            });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => console.log(`ignoring exclusion ${row.id}`)
                }
            ]
        });
    }

    return (
        <div>
            { !hiddenUpdateButton &&
            <Button
                type="button"
                color="secondary"
                variant="contained"
                style={{marginRight:3, maxWidth: '50px', maxHeight: '40px', minWidth: '50px', minHeight: '40px'}}
                onClick={viewClick}                
            >
                <Icon icon='compose' size={18} color='#fff' /> 
            </Button>}

            { !hiddenDeleteButton &&
            <Button  
                type="button"
                color="inherit"
                variant="contained"
                onClick={deleteClick}
                disabled={deleting}
                style={{maxWidth: '50px', maxHeight: '40px', minWidth: '50px', minHeight: '40px'}}
                
            >
                <Icon icon='trash' size={18} color='#000' />   
            </Button>}
        </div>
    );
};

