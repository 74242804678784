import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{background:'#c95b61'}} 
    >
      <Typography variant="body1">
        &copy;{' '}
        <Link
          component="a"
          href="https://www.joaopessoa.pb.gov.br/"
          target="_blank"
        >
          Prefeitura Municipal de João Pessoa
        </Link>
        . 2022
      </Typography>
      {/* <Typography variant="caption" > */}
      PMJP, Cidade que cuida!
      {/* </Typography> */}
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
