import React from 'react';
import CardList from '../../../components/CardList/CardList';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const RegistroAtividadeCfgList = (props) => {
    const columns = [
        { name: 'descricao', title: 'Descrição',  width: 200, filterable: true, content: true},
        { name: '$secretaria.nome$', title: 'Unidade de Lotação', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 300, filterable: true, primary: true },
    ]

    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Secretaria excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid>
                <Grid item>
                    <Card className='cardPersonalData'>
                    <CardHeader title="Configuração do Registro de Atividades" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ?
                            <CardList route={"registroAtividadesCfg"} endpoint={"registroAtividadesCfg"} columns={columns}/> :
                            <GridList modelName={"Configuração do Registro de Atividades"} 
                                editModelRoute={'/registroAtividadesCfg'}
                                newModelRoute={'/registroAtividadesCfg/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'registroAtividadesCfg'}
                                enableActions={true}
                                columns={columns}/>}
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

