import React from 'react';
import AtendimentoForm from './AtendimentoForm';
import ServicoForm from './ServicoForm';
import { useState } from 'react';

const PublicProconAtendimento = () => {
    const [selectedService, setSelectedService] = useState('');

    return (
        selectedService ?
        <AtendimentoForm onBack={() => setSelectedService('')} selectedService={selectedService}/>
        :
        <ServicoForm onSelect={setSelectedService} />
      );
}

export default PublicProconAtendimento;