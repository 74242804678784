import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useMemo } from 'react';
import { useState } from 'react';
import RatingComponent from './RatingComponent';
import { Button } from '@material-ui/core';
import { api } from '../../../services';
import { ToastMessage } from '../../../layouts';

const useQuery = () => {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

const PublicProconConclusaoAtendimento = () => {
    const [rating, setRating] = useState(0);
    const [token, setToken] = useState('');
    const [startedAt, setStartedAt] = useState('');
    const [finishedAt, setFinishedAt] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const query = useQuery();

    /*useEffect(() => {
        setToken(query.get("token"));
        setStartedAt(query.get("startedAt"));
        setFinishedAt(query.get("finishedAt"));

        query.set("token", "");
        query.set("startedAt", "");
        query.set("finishedAt", "");
    }, []);*/

    const sendAssessment = (e) => {
        e.preventDefault();
        setSubmitting(true);
        api.post('/public/procon/conclusao', {
            token: query.get("token"),
            startedAt: query.get("startedAt"),
            finishedAt: query.get("finishedAt"),
            rating: rating
        })
            .then((response) => {
                // Gambi para poder fazer auto redirecionamento no TAdvWebBrowser para os tablets
                document.getElementById('redirect_link').click();
            })
            .catch((error) => {
                console.error(error);
                ToastMessage.error("Problemas na conclusão do atendimento. Entre em contato com a administração do sistema.");
            })
            .finally(() => setSubmitting(false));
    }

    return (
        <>
            <a id="redirect_link" href={`/public/proconjp/atendimento`}></a>
            {query.get("token") && query.get("startedAt") && query.get("finishedAt") &&
            <div style={{textAlign: 'center'}}>
                <h2 style={{paddingTop: 40, paddingBottom: 40}}>Como foi seu atendimento?</h2>
                <RatingComponent customOnClick={setRating} />
                <Button style={{marginTop: 40}} type="submit" disabled={submitting} onClick={sendAssessment} color="primary" variant="contained">Avaliar</Button>
            </div>
            }
        </>
    );
}

export default PublicProconConclusaoAtendimento;