import React from 'react';
import { isMobile } from 'react-device-detect';
import CardList from '../../../components/CardList/CardList';
import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import {GridList} from '../../../components/GridList/GridList';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        // margin: "auto",
        maxWidth: "100%",
        // padding: theme.spacing(4),
        width: '100%',
        marginLeft: '1%'
    }
  }));
  
export const RegistrosUsersList = (props) => {
    const columns = [                                
        { name: '$users.name$', title: 'Nome', getCellValue: row => row.users ? row.users.name : null, width: 200, filterable: true, primary: true },
        { name: 'dataRegistro', title: 'Dt. Registro', width: 100, filterable: true,  },
        { name: 'horaRegistro', title: 'hr. Registro', width: 100, filterable: true, secondary: true },
        { name: 'comentario', title: 'Comentário', width: 200, filterable: true, content: true },
        
        { name: '$secretaria.nome$', title: 'Nome', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 200, filterable: true,  },
    ]

    const classes = useStyles();
    const selectedUserId = useSelector(state => state.formRegistroIndividual.comentario);

    
    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid container justify="flex-start" spacing={12} >
                <Grid item lg={12} xs={12}>
                    <Card className='cardPersonalData'>
                    <CardContent>
                        <Grid container spacing={12} wrap="wrap">
                            {isMobile ?
                            <CardList route={"registroAtividades"} endpoint={`registroAtividades/user/${selectedUserId}`} columns={columns} hideAddButton={true} hideEditButton={true} hideDeleteButton={true}/> :
                            <GridList 
                                modelName={"Escalas de Trabalho"} 
                                disableNewButtom={true}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={`registroAtividades/user/${selectedUserId}`}
                                enableActions={false}
                                hiddenDeleteButton={true}
                                tableWidth={800}
                                // style={{marginLeft:100}}
                                tableHeight={400}
                                columns={columns}/>}
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

