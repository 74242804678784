import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AuthState from '../../../../auth/Auth';

import Icon from '../../../../components/icon/icons';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 95,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    paddingStart: 15,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(15),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();

  const pages = [
    {
      title: 'Configurações',
      href: '#admin',
      icon: <Icon icon='phonelinksetup' />,
      parent: '',
      children: [
        {
          title: 'Permissões',
          href: '/grants',
          icon: <Icon icon='lockOpen' />,
          parent: 'Administração',
        },
        {
          title: 'Perfis',
          href: '/profiles',
          icon: <Icon icon='contactmail' />,
          parent: 'Administração',
        },
        {
          title: 'Lista de Usuários',
          href: '/users',
          icon: <Icon icon='users' />,
          parent: 'Administração',
        },
        {
          title: 'Empresas',
          href: '/companies',
          icon: <Icon icon='condominio' />,
          parent: 'Administração',
        },
        {
          title: 'Usuários de Empresas',
          href: '/user/company/profiles',
          icon: <Icon icon='person' />,
          parent: 'Administração',
        },
        {
          title: 'Secretarias',
          href: '/secretarias',
          icon: <Icon icon='condominio' />,
          parent: 'Administração',
        },
      ],
    },
    {
      title: 'ProconJP Atendimentos',
      href: '#proconAtendimentos',
      icon: <Icon icon='more_time' />,
      parent: '',
      children: [
        {
          title: 'Atendimentos',
          href: '/proconjp/atendimentos',
          icon: <Icon icon='playlist_add' />,
          parent: '#proconAtendimentos',
        },
        {
          title: 'Dispositivos',
          href: '/proconjp/devices',
          icon: <Icon icon='mobile-devices' />,
          parent: '#proconAtendimentos',
        },
        {
          title: 'Geolocalização dos Dispositivos',
          href: '/proconjp/devicesMap',
          icon: <Icon icon='map2' />,
          parent: '#proconAtendimentos',
        },
        {
          title: 'Estudantes',
          href: '/proconjp/estudantes',
          icon: <Icon icon='profile' />,
          parent: '#proconAtendimentos',
        },
        {
          title: 'Responsáveis',
          href: '/proconjp/responsaveis',
          icon: <Icon icon='user-tie' />,
          parent: '#proconAtendimentos',
        },
        {
          title: 'Projetos',
          href: '/proconjp/projetos',
          icon: <Icon icon='folders' />,
          parent: '#proconAtendimentos',
        },
        {
          title: 'Relatórios',
          href: '/reports',
          icon: <Icon icon='profile' />,
          parent: '#proconAtendimentos',
        }
      ],
    },
    {
      title: 'Colaborador',
      href: '#funcionarios',
      icon: <Icon icon='colaboradores' />,
      parent: '',
      children: [
        {
          title: 'Colaborador(Crachá) ',
          href: '/users',
          icon: <Icon icon='contactmail' />,
          parent: 'Colaborador',
        },

        {
          title: 'Escalas de Trabalho',
          href: '/escalasAtividades',
          icon: <Icon icon='calendar' />,
          parent: 'Colaborador',
        },
        {
          title: 'Escala Individual',
          href: '/escalasAtividadesIndividual',
          icon: <Icon icon='calendar' />,
          parent: 'Colaborador',
        },        

        {
          title: 'Configurar Registros',
          href: '/registroAtividadesCfg',
          icon: <Icon icon='cog' />,
          parent: 'Colaborador',
        },        

        {
          title: 'Registro Geral',
          href: '/registroAtividades',
          icon: <Icon icon='playlist_add' />,
          parent: 'Colaborador',
        },

        {
          title: 'Registro Individual',
          href: '/registroAtividadesIndividual',
          icon: <Icon icon='playlist_add' />,
          parent: 'Colaborador',
        },
        {
          title: 'Registro Histórico',
          href: '/registroAtividadesHistorico',
          icon: <Icon icon='history' />,
          parent: 'Colaborador',
        },
        
        {
          title: 'Geolocalização',
          href: '/geolocalizacao',
          icon: <Icon icon='map2' />,
          parent: 'Colaborador',
        },
        ],
    },
    {
      title: 'Sec. Habitação',
      href: '#sechabitacao',
      icon: <Icon icon='workHome' />,
      parent: '',
      children: [
        {
          title: 'Candidatos',
          href: '/candidatos',
          icon: <Icon icon='profile' />,
          parent: 'Sec. Habitação',
        },
        
        {
          title: 'Editais',
          href: '/concorrencias',
          icon: <Icon icon='portaria' />,
          parent: 'Sec. Habitação',
        },

        {
          title: 'Inscritos',
          href: '/inscricoes',
          icon: <Icon icon='user-check' />,
          parent: 'Sec. Habitação',
        },
        ],
    },
    {
      title:'Sec. Saúde',
      href: '#secsaude',
      icon: <Icon icon='aid-kit' />,
      parent: '',
      children: [
        {
          title: 'Exames',
          href: '/exames',
          icon: <Icon icon='lab' />,
          parent: 'Sec. Saúde',
        },
        ],
    }, 
    {
      title:'SEPT - SAD',
      href: '#sad',
      icon: <Icon icon='spotify' />,
      parent: '',
      children: [
        {
          title: 'Demanda Completa',
          href: '/demandas',
          icon: <Icon icon='alert1' />,
          parent: 'SAD',
        },
        {
          title: 'Minhas Demanda',
          href: '/demandasSimples',
          icon: <Icon icon='alert1' />,
          parent: 'SAD',
        },
        {
          title: 'Assuntos',
          href: '/assuntos',
          icon: <Icon icon='playlist_add' />,
          parent: 'SAD',
        },
        {
          title: 'SLAs',
          href: '/slas',
          icon: <Icon icon='more_time' />,
          parent: 'SAD',
        },
        ],
    }, 
    {
      title:'Sair',
      href: '#sair',
      icon:  <>
              <IconButton        
                // color="inherit"
                style={{marginLeft:-10}}
                onClick={() => {
                  AuthState.logout(() => {
                    history.push("/login")
                  });
                }}>
                <Icon icon='exit' size='24'/>                
              </IconButton>      
            </>,
      parent: 'root',
    },              
  ];

  return (
    <Drawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} key={pages.title} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
