import React, { useMemo, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Divider, CardContent, CardHeader, Card, Button, CardActions, Typography } from '@material-ui/core';

import './required-field.css';
import { renderCheckbox, renderTextField } from '../../../components/Fields/Fields';
import {  phoneMask } from '../../../services/masks';
import { api, validCpf } from '../../../services';
import { ToastMessage } from '../../../layouts';
import { Loading } from '../../../components/Loading/Loading';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const formName = 'Atendimento';

const validate = values => {
    const errors = {}
    var requiredFields = [
        'nome',
        'cpf',
        'email',
        'telefone'
    ]

    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    // VALIDAÇAO EMAIL
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors['email'] = 'Endereço de Email invalido'
    }
    
    // VALIDAÇAO CPF
    if (values.cpf && !validCpf(values.cpf)) {
        errors['cpf'] = 'Cpf inválido';
    }

    return errors
}

const useQuery = () => {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

let Form = (props) => {
    const { handleSubmit, valid, onBack, selectedService } = props;
    const [submitting, setSubmitting] = useState(false);
    const [token, setToken] = useState('');
    const [isDeaf, setIsDeaf] = useState(true);
    const upper = value => value && value.toUpperCase()

    const query = useQuery();

    const getComputerName = () => {
        return query.get("origin") || "localhost";
    }

    const onSubmit = values => {
        setSubmitting(true)
        values['origin'] = getComputerName();
        values['selection'] = selectedService;
        return api.post('/public/procon/atendimento', values)
            .then((response) => {
                // redirecionar para a tela de atendimento com o token gerado na resposta
                setToken(response.data.token);
                setIsDeaf(values.deficienteAuditivo);
                // Gambi para poder fazer auto redirecionamento no TAdvWebBrowser para os tablets
                document.getElementById('redirect_link').click();
            })
            .catch((error) => {
                console.error(error);
                ToastMessage.error("Problemas no cadastro das informações. Entre em contato com a administração do sistema.");
            })
            .finally(() => setSubmitting(false));
    }

    return (
        token ? <>
            <h2>Aguarde, redirecionando...</h2>
            <a id="redirect_link" href={`/public/proconjp/videoconference?token=${token}&deficienteAuditivo=${isDeaf}`}></a>
        </>
        :
        <form onSubmit={handleSubmit(onSubmit)} style={{marginTop: '8%'}} autoComplete="off" >
            <Grid container wrap="wrap" className="row">
                <Divider />
                {submitting &&
                    <div id="overlay" style={{
                        backgroundColor: '#EFEFEF',
                        position: 'fixed',
                        width: '100%',
                        height: '100%',
                        zIndex: '1000',
                        top: '0px',
                        left: '0px',
                        opacity: '.5',
                        filter: 'alpha(opacity=50)'
                    }}>
                        <Loading/>
                    </div>
                }

                <Card className="personalData" style={{width: '100%', }}>
                    <CardHeader title={
                        <Typography gutterBottom variant="h3" component="h2">
                            Informe seus dados
                        </Typography> } 
                    /> 
                    <CardContent>
                        <Grid container spacing={2} wrap="wrap">
                            <Grid item xs={2}/>                                                           
                            <Grid item xs={10}>
                                <Field name="nome" type="text" style={{maxWidth: '100%', width: '80%' }} component={renderTextField} label="Nome" normalize={upper} {...{ required: true }} />
                            </Grid>

                            <Grid item xs={2}/>                               
                            <Grid item xs={10}>
                                <Field label="Telefone" name="telefone" style={{maxWidth: '100%', width: '80%'}} component={renderTextField} {...phoneMask} {...{ required: true }}/>
                            </Grid>

                            <Grid item xs={2}/>
                            <Grid item xs={10}>
                                <Field label="Deficiente auditivo?" name="deficienteAuditivo" style={{maxWidth: '100%', width: '80%'}} component={renderCheckbox} {...{ required: true }}/>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>

                <Divider />

                <Card style={{width: '100%'}}>
                    <CardActions style={{ margin: "1rem" }}>
                        <Grid container direction="row" justify="space-evenly" alignItems="center">
                            <Button type="button"  color="inherit" variant="contained" onClick={onBack}>
                                Voltar
                            </Button>

                            <Button type="submit" color="primary" variant="contained" disabled={!valid}>
                                Avançar
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </form>
    );
};

Form = reduxForm({
    form: formName,
    validate,
})(Form);

export default Form;

