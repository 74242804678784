import React, { useEffect, useState } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import {api} from "../../../services";
import { ToastMessage } from '../../../layouts';

const CustomMarker = (props) => {

    return (
        <Marker {...props} />
    );
};

const ProconDeviceMap = (props) => {
    const [deviceList, setDeviceList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.get("proconjp/devices").then(response => {
            setDeviceList(response.data && response.data.rows);
        })
        .catch(error => {
            ToastMessage.error(error.message);
        })
        .finally(setLoading(false));
    }, []);
    const center = { lat: -7.111588, lng: -34.850261 };

    const getDeviceStatusMarker = (deviceStatus) => {
        if (deviceStatus === 'running') return '/images/MarkerDeviceOnline.png';
        else if (deviceStatus === 'failure') return '/images/MarkerDeviceError.png';
        else return '/images/MarkerDeviceOffline.png';
    }

    const MyMapComponent = withScriptjs(withGoogleMap((props) => 
        <GoogleMap
            defaultZoom={15}
            center={center}
        >
            {deviceList && 
                deviceList.map((device) =>(
                    <CustomMarker 
                        icon={{url:getDeviceStatusMarker(device.status) }}
                        key={device.id} 
                        position={{lat: parseFloat(device.latitude), lng: parseFloat(device.longitude)}} 
                        title={device.machineName}
                        {...props}
                    />
                ))
            }

        </GoogleMap>
    ))
    
    return (
        <>
            {loading ? <div>Carregando...</div>
            :
            <MyMapComponent
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmiTdTowI7DxNIodiBXI0d6JkCqOuGwA0&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{maxWidth: '100%', width: 1000 , height: `100%` }} />}
                containerElement={<div style={{maxWidth: '100%', width: 1000 , height: `100%` }} />}
                mapElement={<div style={{ height: `400px`}} />}
            />
            }
        </>
    )
}

export default ProconDeviceMap;