import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,  
  Divider,
  Button,
  IconButton,
  Grid,
  Typography 
} from '@material-ui/core';
import AuthState from '../../../../auth/Auth';
import { useHistory } from 'react-router';

import Icon from '../../../../components/icon/icons';


//import { getToken, deleteToken } from "../../../../services/firebaseNotification/index";

// import LOCAL_URL from '../../../../services/qrCodeConfig'

const useStyles = makeStyles(() => ({
  root: {}
}));

const Cracha = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false)


  const handleClick = (e) => {
    AuthState.getUserInfo().then( (luser) => {
        history.push(`/crachavirtual/${luser.id}`);
    })    
  }
  
  const handleRegistrar = (e) => {
    history.push(`/registroAtividadesIndividual`);
  }   

  
  const handleHistorico = (e) => {
    history.push(`/registroAtividadesHistorico`);
  } 
  
  const handleEscalas = (e) => {
    history.push(`/escalasAtividadesIndividual`);
  } 


  const handleClickGeoLocation = (e) => {

    navigator.geolocation.getCurrentPosition(success, error, options);
    // navigator.geolocation.getCurrentPosition((position) => {
    //   console.log("Latitude is :", position.coords.latitude);
    //   console.log("Longitude is :", position.coords.longitude);
    //   console.log(position)
    // });   
  }  

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
  
  function success(pos) {
    var crd = pos.coords;
  
    console.log('Sua posição atual é:');
    console.log('Latitude : ' + crd.latitude);
    console.log('Longitude: ' + crd.longitude);
    console.log('Mais ou menos ' + crd.accuracy + ' metros.');
  };
  
  function error(err) {
    console.warn('ERROR(' + err.code + '): ' + err.message);
  };  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          title="Menu Colaborador"
          // subheader="Exibir Cracha"
          
        />
        <Divider />

        <Grid container spacing={3} wrap="wrap">

            <Grid direction="column" justifyContent="center" alignItems="center"  >              
              <Button
                color="primary"
                variant="outlined"
                style={{ maxWidth: '100%', width: 120, margin:15, height:70}}
                onClick={handleClick}
              >                
                <Icon icon='contactmail' size='36'/>     
                
              </Button>
              <Typography align="center" variant="h4" onClick={handleClick} >
                Cracha
              </Typography> 
            </Grid>   

            <Grid direction="column" justifyContent="center" alignItems="center"   >              
              <Button
                color="primary"
                variant="outlined"
                style={{ maxWidth: '100%', width: 120, margin:15, height:70}}
                onClick={handleRegistrar}
              >                
                <Icon icon='playlist_add' size='36'/>     
                
              </Button>
              <Typography align="center" variant="h4" onClick={handleRegistrar} >
                Registrar
              </Typography> 
            </Grid>   

            <Grid direction="column" justifyContent="center" alignItems="center" >              
              <Button
                color="primary"
                variant="outlined"
                style={{ maxWidth: '100%', width: 120, margin:15, height:70}}
                onClick={handleEscalas}
              >                
                
                <Icon icon='calendar' size='36'/>
                
              </Button>
              <Typography align="center" variant="h4" onClick={handleEscalas} >
                Escalas
              </Typography> 
            </Grid>              

            <Grid direction="column" justifyContent="center" alignItems="center" >              
              <Button
                color="primary"
                variant="outlined"
                style={{ maxWidth: '100%', width: 120, margin:15, height:70}}
                onClick={handleHistorico}
              >                
                  <Icon icon='history' size='36' style={{height:100}} />

              </Button>
              <Typography align="center" variant="h4" onClick={handleHistorico} >
                Histórico
              </Typography> 
            </Grid>               

        </Grid>
          

        <Divider style={{marginTop:50}} />          
 

      </form>
    </Card>
  );
};

Cracha.propTypes = {
  className: PropTypes.string
};

export default Cracha;
