import React from 'react';

//import {createIconSetFromIcoMoon} from "react-native-vector-icons";
//import IcomoonConfig from "./theme/selection.json"
//const Icomoon = createIconSetFromIcoMoon(IcomoonConfig);

//import IcoMoon from "react-icomoon";
import IcomoonReact from "icomoon-react";
import iconSet from "./selection.json";

/* const Icon = (props: any) => {
    return (
        <Icomoon 
            color={props.color}
            size={props.size}
            name={props.name}
        />
    )
} */

const Icon = ({ ...props }) => {
    return <IcomoonReact 
    iconSet={iconSet}
    icon={props.icon}
    size={props.size}
    color={props.color}
     />;
  };

export default Icon;