import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    mainForm: {
        // padding: theme.spacing(4),
        margin: "auto",
        textAlign: "center",
        maxWidth: "100%",
        // background: 'linear-gradient(45deg, #FD3232 30%, #F08E46 90%)',
        // background: 'linear-gradient(180deg, #d9534f 50%, #be1019 50%)',

    },
    container: {
        padding: theme.spacing(3),
        maxWidth: "100%",
    },
    loginInput: {

    },
    passwordInput: {

    },
    mainLeft: {
        padding: theme.spacing(0),
        
    },
    mainRigth: {
        // padding: theme.spacing(12),
        backgroundColor: '#FFF',
        paddingTop: 50,
        borderRadius: 20,
        maxWidth: "100%",        
        alignContent: "center"
    },
    image: {
        // width: 512,
        // height: 512,
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',        
        alignContent: "center"
    },  
    text1: {
        marginRight: theme.spacing(1),
        color:'#FFF' ,
        textShadow: '2px 2px rgba(255, 255, 255, 0.2)',
        letterSpacing: 2,
        fontSize: 18,
        textAlign: "center",               
      }, 
    text2: {
        color: '#111111',
        marginRight: theme.spacing(1),
        textShadow: '2px 2px rgba(51, 51, 51, 0.2)',
        letterSpacing: 3,
        fontSize: 40,
        alignContent: "center"
        
      }, 
    text3: {
        color: '#FD3232',
        background: '#FFF',
        marginRight: theme.spacing(1),
        textShadow: '2px 2px rgba(51, 51, 51, 0.2)',
        letterSpacing: 1,
        fontSize: 12,
        marginTop: 20        
      },  
    text4: {
        color: '#111111',
        background: '#FFF',
        marginRight: theme.spacing(1),
        textShadow: '2px 2px rgba(51, 51, 51, 0.2)',
        letterSpacing: 1,
        fontSize: 18,
        margin: 30        ,
        alignContent: "center"
      },                
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        paddingBottom: 50,
    },
    forgotPassword: {
        color: '#F08E46'
    }
}));

export default useStyles;