import React from 'react';
import { isMobile } from 'react-device-detect';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CardList from '../../../components/CardList/CardList';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
}));

export const ProconDeviceHistoryList = (props) => {
    const id = props.id;

    const columns = [
        { name: 'deviceTimestamp', title: 'Data/Hora (Dispositivo)', filterable: true, width: 220},
        { name: 'status', title: 'Situação', filterable: true, width: 100 },
        { name: 'statusDetail', title: 'Detalhe Situação', filterable: true, width: 250 },
    ]

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid>
                <Grid item>
                    <Card className='cardPersonalData'>
                    <CardHeader title="Histórico" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ? 
                                <CardList route={`proconjp/devices/${id}/history`} endpoint={`proconjp/devices/${id}/history`} columns={columns}/> :
                                <GridList modelName={"Dispositivos"} 
                                    endpoint={`proconjp/devices/${id}/history`}
                                    pageSize={5}
                                    enableActions={false}
                                    disableNewButtom={true}
                                    columns={columns}/>
                            }
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

