const BankList = [
    {"codigo": "197", "nome": "Stone Pagamentos"},
    {"codigo": "748", "nome": "Banco Cooperativo Sicredi S.A."},
    {"codigo": "117", "nome": "Advanced Cc Ltda"},
    {"codigo": "735", "nome": "Neon Pagamentos"},
    {"codigo": "340", "nome": "Superdigital"},
    {"codigo": "290", "nome": "PagBank"},
    {"codigo": "121", "nome": "Banco Agibank S.A."},
    {"codigo": "237", "nome": "Next"},
    {"codigo": "212", "nome": "Banco Original"},
    {"codigo": "260", "nome": "Nubank"},
    {"codigo": "280", "nome": "Banco Willbank"},
    {"codigo": "172", "nome": "Albatross Ccv S.A"},
    {"codigo": "323", "nome": "Mercado Pago"},
    {"codigo": "188", "nome": "Ativa Investimentos S.A"},
    {"codigo": "380", "nome": "PicPay"},
    {"codigo": "280", "nome": "Avista S.A. Crédito, Financiamento e Investimento"},
    {"codigo": "080", "nome": "B&T Cc Ltda"},
    {"codigo": "654", "nome": "Banco A.J.Renner S.A."},
    {"codigo": "246", "nome": "Banco ABC Brasil S.A."},
    {"codigo": "075", "nome": "Banco ABN AMRO S.A"},
    {"codigo": "121", "nome": "Banco Agibank S.A."},
    {"codigo": "025", "nome": "Banco Alfa S.A."},
    {"codigo": "641", "nome": "Banco Alvorada S.A."},
    {"codigo": "065", "nome": "Banco Andbank (Brasil) S.A."},
    {"codigo": "213", "nome": "Banco Arbi S.A."},
    {"codigo": "096", "nome": "Banco B3 S.A."},
    {"codigo": "033", "nome": "Banco SANTANDER"},
    {"codigo": "318", "nome": "Banco BMG S.A."},
    {"codigo": "752", "nome": "Banco BNP Paribas Brasil S.A."},
    {"codigo": "107", "nome": "Banco BOCOM BBM S.A."},
    {"codigo": "063", "nome": "Banco Bradescard S.A."},
    {"codigo": "031", "nome": "Código Banco Beg S.A."},
    {"codigo": "122", "nome": "Banco Bradesco BERJ S.A."},
    {"codigo": "204", "nome": "Banco Bradesco Cartões S.A."},
    {"codigo": "394", "nome": "Banco Bradesco Financiamentos S.A."},
    {"codigo": "237", "nome": "Banco Bradesco S.A."},
    {"codigo": "218", "nome": "Banco BS2 S.A."},
    {"codigo": "208", "nome": "Banco BTG Pactual S.A."},
    {"codigo": "336", "nome": "Banco C6 S.A – C6 Bank"},
    {"codigo": "473", "nome": "Banco Caixa Geral – Brasil S.A."},
    {"codigo": "104", "nome": "Banco Caixa Econômica Federal"},
    {"codigo": "412", "nome": "Banco Capital S.A."},
    {"codigo": "040", "nome": "Banco Cargill S.A."},
    {"codigo": "368", "nome": "Banco Carrefour"},
    {"codigo": "266", "nome": "Banco Cédula S.A."},
    {"codigo": "739", "nome": "Banco Cetelem S.A."},
    {"codigo": "233", "nome": "Banco Cifra S.A."},
    {"codigo": "745", "nome": "Banco Citibank S.A."},
    {"codigo": "241", "nome": "Banco Clássico S.A."},
    {"codigo": "756", "nome": "Banco Cooperativo do Brasil S.A. – BANCOOB"},
    {"codigo": "748", "nome": "Banco Cooperativo Sicredi S.A."},
    {"codigo": "222", "nome": "Banco Credit Agricole Brasil S.A."},
    {"codigo": "505", "nome": "Banco Credit Suisse (Brasil) S.A."},
    {"codigo": "069", "nome": "Banco Crefisa S.A."},
    {"codigo": "003", "nome": "Banco da Amazônia S.A."},
    {"codigo": "083", "nome": "Banco da China Brasil S.A."},
    {"codigo": "707", "nome": "Banco Daycoval S.A."},
    {"codigo": "051", "nome": "Banco de Desenvolvimento do Espírito Santo S.A."},
    {"codigo": "300", "nome": "Banco de La Nacion Argentina"},
    {"codigo": "495", "nome": "Banco de La Provincia de Buenos Aires"},
    {"codigo": "494", "nome": "Banco de La Republica Oriental del Uruguay"},
    {"codigo": "335", "nome": "Banco Digio S.A"},
    {"codigo": "001", "nome": "Banco do Brasil S.A."},
    {"codigo": "047", "nome": "Banco do Estado de Sergipe S.A."},
    {"codigo": "037", "nome": "Banco do Estado do Pará S.A."},
    {"codigo": "041", "nome": "Banco do Estado do Rio Grande do Sul S.A."},
    {"codigo": "004", "nome": "Banco do Nordeste do Brasil S.A."},
    {"codigo": "196", "nome": "Banco Fair Corretora de Câmbio S.A"},
    {"codigo": "265", "nome": "Banco Fator S.A."},
    {"codigo": "224", "nome": "Banco Fibra S.A."},
    {"codigo": "626", "nome": "Banco Ficsa S.A."},
    {"codigo": "094", "nome": "Banco Finaxis S.A."},
    {"codigo": "612", "nome": "Banco Guanabara S.A."},
    {"codigo": "012", "nome": "Banco Inbursa S.A."},
    {"codigo": "604", "nome": "Banco Industrial do Brasil S.A."},
    {"codigo": "653", "nome": "Banco Indusval S.A."},
    {"codigo": "077", "nome": "Banco Inter S.A."},
    {"codigo": "249", "nome": "Banco Investcred Unibanco S.A."},
    {"codigo": "184", "nome": "Banco Itaú BBA S.A."},
    {"codigo": "029", "nome": "Banco Itaú Consignado S.A."},
    {"codigo": "479", "nome": "Banco ItauBank S.A."},
    {"codigo": "341", "nome": "Banco Itaú"},
    {"codigo": "376", "nome": "Banco J. P. Morgan S.A."},
    {"codigo": "422", "nome": "Banco Safra"},
    {"codigo": "217", "nome": "Banco John Deere S.A."},
    {"codigo": "076", "nome": "Banco KDB S.A."},
    {"codigo": "757", "nome": "Banco KEB HANA do Brasil S.A."},
    {"codigo": "600", "nome": "Banco Luso Brasileiro S.A."},
    {"codigo": "243", "nome": "Banco Máxima S.A."},
    {"codigo": "720", "nome": "Banco Maxinvest S.A."},
    {"codigo": "389", "nome": "Banco Mercantil do Brasil S.A."},
    {"codigo": "370", "nome": "Banco Mizuho do Brasil S.A."},
    {"codigo": "746", "nome": "Banco Modal S.A."},
    {"codigo": "066", "nome": "Banco Morgan Stanley S.A."},
    {"codigo": "456", "nome": "Banco MUFG Brasil S.A."},
    {"codigo": "007", "nome": "Banco Nacional de Desenvolvimento Econômico e Social – BNDES"}
]

export default BankList;