import React, { useState } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { Card, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { renderTextField  } from '../../../../../components/Fields/Fields';
import BasicForm from '../../../../../components/BasicForm';
import { cpfMask } from '../../../../../services/masks';
import { api, validCpf } from '../../../../../services';
import { ToastMessage } from '../../../../../layouts';

const formName = 'Projetos';
const modelName = 'proconjp/projetos';
const label = 'Projetos';

const validate = values => {
    const errors = {}
    var requiredFields = [
        'nomeEscola',
        'serieEscolar',
        'responsavelId',
        'estudanteId'
    ]

    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    return errors
}

let Form = (props) => {
    const [entity, setEntity] = useState({})
    const [responsavel, setResponsavel] = useState({})
    const [estudante, setEstudante] = useState({})
    const upper = value => value && value.toUpperCase()

    const afterLoadEntity = (entity) => {
        setEntity(entity);
        setResponsavel(entity.responsavel);
        setEstudante(entity.estudante);

        props.dispatch(change(formName, 'nomeResponsavel', entity.responsavel['nome']));
        props.dispatch(change(formName, 'responsavelId', entity.responsavel['cpf']));
        props.dispatch(change(formName, 'nomeEstudante', entity.estudante['nome']));
        props.dispatch(change(formName, 'estudanteId', entity.estudante['cpf']));
    }

    const fetchResponsavelData = (event) => {
        const cpf = event.target.value.replaceAll('-', '').replaceAll('.', '');

        if (validCpf(cpf)) {
            api.get(`/proconjp/responsaveis?filter=[cpf,contains,${cpf}]`).then(
                (response) => {  
                    const fetchedResponsavel = response.data && response.data.rows && response.data.rows[0];
                    if (fetchedResponsavel) {
                        setResponsavel(fetchedResponsavel);
                        props.dispatch(change(formName, 'nomeResponsavel', fetchedResponsavel['nome']));
                    } else {
                        ToastMessage.error(`Responsável ${cpf} não encontrado`);
                        props.dispatch(change(formName, 'responsavelId', undefined));
                    }
                }
            ).catch(err => {
                ToastMessage.error(`Responsável ${cpf} não encontrado`);
                props.dispatch(change(formName, 'responsavelId', undefined));
            });
        }
    }

    const fetchEstudanteData = (event) => {
        const cpf = event.target.value.replaceAll('-', '').replaceAll('.', '');

        if (validCpf(cpf)) {
            api.get(`/proconjp/estudantes?filter=[cpf,contains,${cpf}]`).then(
                (response) => {  
                    const fetchedEstudante = response.data && response.data.rows && response.data.rows[0];
                    if (fetchedEstudante) {
                        setEstudante(fetchedEstudante);
                        props.dispatch(change(formName, 'nomeEstudante', fetchedEstudante['nome']));
                    } else {
                        ToastMessage.error(`Estudante ${cpf} não encontrado`);
                        props.dispatch(change(formName, 'estudanteId', undefined));
                    }
                }
            ).catch(err => {
                ToastMessage.error(`Estudante ${cpf} não encontrado`);
                props.dispatch(change(formName, 'estudanteId', undefined));
            });
        }
    }

    const beforeSubmit = (values) => {
        values.estudanteId = estudante.id;
        values.responsavelId = responsavel.id;
    }

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            customBeforeSubmit={beforeSubmit}
            content={
                <Grid container spacing={2} wrap="wrap">
                    <Grid item xs={9}>
                        <Field name="nomeEscola" type="text" style={{width: '100%'}} component={renderTextField} label="Escola" normalize={upper} {...{ required: true }} />
                    </Grid>

                    <Grid item xs={3}>
                        <Field name="serieEscolar" type="text" style={{width: '100%'}} component={renderTextField} label="Série" normalize={upper} {...{ required: true }} />
                    </Grid>

                    <Card style={{width: '100%'}}>
                        <CardHeader title='Dados do Responsável' />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2} wrap="wrap">
                                <Grid item xs={3}>
                                    <Field name="responsavelId" type="text" onBlur={fetchResponsavelData} component={renderTextField} label="CPF" normalize={upper} style={{ width: '100%' }} {...{required: true}} {...cpfMask()} />
                                </Grid>

                                <Grid item xs={9}>
                                    <Field name="nomeResponsavel" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ width: '100%' }} {...{value: responsavel.name, disabled: true}} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card style={{width: '100%'}}>
                        <CardHeader title='Dados do Estudante' />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2} wrap="wrap">
                                <Grid item xs={3}>
                                    <Field name="estudanteId" type="text" onBlur={fetchEstudanteData} component={renderTextField} label="CPF" normalize={upper} style={{ width: '100%' }} {...{required: true}} {...cpfMask()} />
                                </Grid>

                                <Grid item xs={9}>
                                    <Field name="nomeEstudante" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ width: '100%' }} {...{value: estudante.name, disabled: true}} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
})(Form);

export default Form;

