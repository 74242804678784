import { createTextMask, createNumberMask } from 'redux-form-input-masks';

export const contaBancoMask = createTextMask({
    pattern: '999999999-9',
})

export const agenciaBancoMask = createTextMask({
    pattern: '99999-9',
})

export const cpfMask = (callback) => createTextMask({
    pattern: '999.999.999-99',
    onChange: cpf => {
        if (cpf && (cpf.length === 11)) {
            callback && callback(cpf);
        }
    }
})

export const cnpjMask = (callback) => createTextMask({
    pattern: '99.999.999/9999-99',
    onChange: cnpj => {
        if (cnpj && (cnpj.length === 14)) {
            callback && callback(cnpj);
        }
    }
})

export const phoneMask = createTextMask({
    pattern: '(99)99999-9999',
})

export const cepMask = createTextMask({
    pattern:'99999-999'
})

export const moneyMask = createNumberMask({
    prefix: 'R$ ',
    decimalPlaces: 2,
    locale: 'pt-BR',
})

export const numberMask = createNumberMask({
    prefix: '',
    decimalPlaces: 2,
    locale: 'pt-BR',
})

export const numberMaskNoDescimal = createNumberMask({
    prefix: '',
    decimalPlaces: 0,
    locale: 'pt-BR',
})
export const newPlateVehicleMask = createTextMask({
    pattern: 'AAA9A99',
})

export const plateVehicleMask = createTextMask({
    pattern: 'AAA-9999',
})

export const locationNumberMask = createNumberMask({
    prefix: '',
    decimalPlaces: 5
})

export const DateMaskDDMMYYYY = createTextMask({
    pattern: '99/99/9999',
})

export const TimeMaskHHMMSS = createTextMask({
    pattern: '99:99:99',
})

export const DateMaskDDMMYYYYHHMMSS = createTextMask({
    pattern: '99-99-9999 99:99:99',
})