import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { makeStyles } from '@material-ui/styles';


const useQuery = () => {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    }
}));

const PublicProconVideoConference = () => {
    const [token, setToken] = useState('');
    const [isDeaf, setIsDeaf] = useState(false);
    const [redirecting, setRedirecting] = useState(false)
    const classes = useStyles();
    const query = useQuery();

    const V4H_AUTH_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTI3NzNiZi03ZTE4LTQwZjItYmEyZC1jYTQ2ZTljNjQwNmMiLCJpYXQiOjE2ODkxOTU3MTR9.TGWAMt1wJeOA_xpaATWIkcLwDy4qnv6xz2zIa_b5u18';

    useEffect(() => {
        setToken(query.get("token"));
        setIsDeaf(query.get("deficienteAuditivo"));
        query.set("token", "");
        query.set("deficienteAuditivo", "")
    }, []);

    const callbackUrl = () => btoa(
        `${window.location.origin}/public/proconjp/conclusao&token=${token}`
    )

    const redirect = () => {
        setInterval(() => {
            if (!redirecting && document.getElementById('redirect_link')) {
                setRedirecting(true);
                document.getElementById('redirect_link').click();
                console.log('redirecting...');
            }
        }, 1000)
    }

    return (
        <div className={classes.root}>
            {token ?
                <> 
                    {redirect()}
                    <h2>Aguarde, redirecionando...</h2>
                    <a id="redirect_link" href={`https://campanha.dev.v4h.cloud/?token=${V4H_AUTH_TOKEN}&libras=${isDeaf}&callback=${callbackUrl()}`}></a>
                </>
                :
                <Grid container spacing={4}>
                    <h2>Hello: {token}</h2>
                </Grid>
            }
        </div>
      );
}

export default PublicProconVideoConference;