import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,  
  Divider,
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import AuthState from '../../../../auth/Auth';
import { useHistory } from 'react-router';
import Icon from '../../../../components/icon/icons';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e) => {
    AuthState.getUserInfo().then( (luser) => {
      history.push(`/users/${luser.id}`);
    })    
  }  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          title="Perfil do Usuário"
        />

        <Divider />


        <Grid container spacing={3} >

            <Grid direction="column" justifyContent="center" alignItems="center"  >              
              <Button
                color="primary"
                variant="outlined"
                style={{ maxWidth: '100%', width: 120, margin:15, height:70}}
                onClick={handleClick}
              >                
                  <Icon icon='person' size='36'/>                     
              </Button>
              <Typography align="center" variant="h4" onClick={handleClick} >
                Alterar
              </Typography> 
            </Grid>   

        </Grid>
          

        <Divider style={{marginTop:30}} />     


      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
