import axios from 'axios';
import API_URL from './apiConfig';
import AuthState from '../auth/Auth';
import { ToastMessage } from '../layouts';

const api = axios.create({
  baseURL: API_URL
});

api.interceptors.response.use(async response => {
  return response;
}, async error => {
  if (error.response) {
    if (400 === error.response.status) {
      const msg = error.response.data.error;
      if (msg) {
        ToastMessage.error(`Problemas no servidor: ${msg}`);
      }
    } else if (401 === error.response.status) {
      const {config} = error.response;

      if (config && config.url && config.url.toLowerCase() === "login" && config.method && config.method.toLowerCase() === "post") {
        throw Error(error.response.data && error.response.data.error);
      } else {
        AuthState.logout(() => {
          if (!loginRoute(error.response)) {
            ToastMessage.error('Sessão expirada');
            window.location = "/login";
          }
        });
      }
    } else if (403 === error.response.status) {
      ToastMessage.warning('Você não tem permissão para acessar esse recurso');
    } else if (500 === error.response.status) {
      ToastMessage.error('Erro interno no servidor')
    }
  }
  return Promise.reject(error);
}
);

api.interceptors.request.use(async config => {
  if (config.url !== 'login') {
    const token = AuthState.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

api.getApiURL = () => {
  return API_URL
}

api.doUploadImage = (context, entity, image) => {
  if (image) {
      return axios
      .put(
          image.url,
          image.file,
          {
            headers: {
                'Content-Type': image.file.type,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Access-Control-Allow-Methods': 'PUT, POST, GET, DELETE, OPTIONS'
            },
            reportProgress: true
        }
      ).then((response) => {
          return api.put(`/${context}/upload/${entity.id}`, {ownerId: entity.id, context: image.context})
          .then((response) => {
              ToastMessage.success('Upload de imagem concluído.');
              return response;
          })
          .catch((error) => console.error(error))
      })
      .catch((error) => {
          // handle your error
          console.error(error);
          ToastMessage.error('Não foi possível fazer upload da image');
      })
  }
}

api.doUploadFile = (file, onSuccess, originalFilename) => {
  if (file) {
      return axios
      .put(
        file.url,
        file.file,
          {
              headers: {
                  'Content-Type': file.file.type,
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                  'Access-Control-Allow-Methods': 'PUT, POST, GET, DELETE, OPTIONS'
              },
              reportProgress: true
          }
      ).then((response) => {
        if (onSuccess) {
          onSuccess(response, file, originalFilename, file.file.type);
        }
      })
      .catch((error) => {
          // handle your error
          console.error(error);
          ToastMessage.error('Não foi possível fazer upload do arquivo');
      })
  }
}

api.doDeleteFile = (context, entity, file) => {
  if (context) {
      return axios
      .delete(
        file.url,
        {
          headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
              'Access-Control-Allow-Methods': 'PUT, POST, GET, DELETE, OPTIONS'
          },
          reportProgress: true
        }
      )
      .then((response) => {
          return api.delete(`/${context}/upload/${entity.id}/${file.id}`).then(() => {
              ToastMessage.success('Arquivo excluído com sucesso.');

              return response;
          })
          .catch((error) => {
            console.error(error)
          })
      })
      .catch((error) => {
          // handle your error
          console.error(error);
          ToastMessage.error('Não foi possível fazer a exclusão do arquivo');
      })
  }
}

api.doDeleteImage = (context, entity, image) => {
  if (context) {
      return axios
      .delete(
          image.url,
          {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Access-Control-Allow-Methods': 'PUT, POST, GET, DELETE, OPTIONS'
            },
            reportProgress: true
        }
      )
      .then((response) => {
          return api.delete(`/${context}/upload/${entity.id}/${image.id}`).then(() => {
              ToastMessage.success('Imagem excluída com sucesso.');
              return response
          })
          .catch((error) => {
            console.error(error)
          })
      })
      .catch((error) => {
          // handle your error
          console.error(error);
          ToastMessage.error('Não foi possível fazer upload da image');
      })
  }
}

const loginRoute = (response) => {
  return response && response.config && response.config.url && response.config.url.endsWith("/login");
}

export default api;
