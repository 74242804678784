import React, {useState, useEffect} from 'react';
import AuthState from '../../auth/Auth';
import authenticate from '../../auth/AuthPromise';
import {Redirect} from 'react-router-dom';
import { Button, Grid, Link } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { renderTextField, renderSelectSearchField } from '../Fields/Fields';
import { cpfMask } from '../../services/masks';
import api from '../../services/api';
import Divider from '@material-ui/core/Divider';
import { ToastMessage } from '../../layouts';
import useStyles from './loginFormStyle';
import validate from './loginFormHelper';
import ReCAPTCHA from 'react-google-recaptcha';
import OneSignal from 'react-onesignal';
import apiNotify from '../../services/apiNotify'

let LoginForm = (props) => {
    const { handleSubmit, submitting } = props;
    const [companyList, setCompanyList] = useState([]);
    const [logged, setLogged] = useState(AuthState.getAuthenticated());
    const [isAdmin, setIsAdmin] = useState((AuthState.getProfileInfo() && AuthState.getProfileInfo().isAdmin) || false)
    const [recaptched, setRecaptched] = useState(false)
    const classes = useStyles();
    const [checkNotificationsEnabled, setCheckNotificationsEnabled] = useState(false)

    useEffect(() => {
        OneSignal.init({appId: "21498576-1c88-408e-b9eb-c00a77de94d7"});
        if (!checkNotificationsEnabled){
            setCheckNotificationsEnabled(true);
            OneSignal.isPushNotificationsEnabled().then((resp) =>{
                if (!resp) {
                    ToastMessage.warning('Favor click no sininho a baixo para receber as notificações!');
                } 
            });
        }

      })
    

    const login = (values) => {


        if (values && values.cpf && values.password && values.companyId) {
            return authenticate(values)
            .then(user => {
                setIsAdmin(user.data.profile.isAdmin);
                setLogged(true) 
                
                OneSignal.getUserId().then((playerId) => {
                    if(playerId){
                        apiNotify.put('/users',{userId:user.data.user.id, playerId:playerId}).then(
                            (res) => {console.log(res)}
                        )
                    } else {
                            OneSignal.getSubscription()
                            .then((isSubscripted) => {
                                if (isSubscripted) {
                                    OneSignal.getUserId().then((playerId) => {
                                        if (playerId){
                                            console.log(playerId);
                                            console.log(user.data.user.id);
                                            apiNotify.put('/users',{userId:user.data.user.id, playerId:playerId}).then(
                                                (res) => {console.log(res)}
                                            )
                                        } else {
                                            ToastMessage.warning('Favor click no sininho a baixo para receber as notificações!');
                                        }
                                        
                                    })
                                } else {
                                    ToastMessage.warning('Favor click no sininho a baixo para receber as notificações!');
                                }
                                
                            })
                    }
                })
               
                ToastMessage.success('Você está logado!');
            })
            .catch(error => {
                setLogged(false)
                setIsAdmin(false);
                ToastMessage.error('Usuário ou senha inválida');
            })
        }
    }

    const onChange = (value) => {
        setRecaptched(true);
    }

    const onExpired = (value) => {
        setRecaptched(false);
    }

    const getCompanyList = () => {
        api.get('public/companies?pageSize=-1').then(list => {
            setCompanyList(list.data.rows);
        });
    }

    const createList = () => {
        let list = []
        if (companyList && companyList.length > 0) {
            companyList.forEach((entity) => {
                list.push({name: entity.name, value: entity.id})
            })
        }
        return list;
    }

    useEffect(() => getCompanyList(), []);
    
    if (logged) {
        if (isAdmin) {
            return <Redirect from="/login" to="/home"/>
        } else {
            return <Redirect from="/login" to="/home"/>
        }        
    } else {
        return (
            <form name="loginForm" onSubmit={handleSubmit(login)}>
                <div className={classes.mainForm}>
                    <br></br>
                    <Grid container spacing={2} className={classes.container} wrap="wrap">
                        {/* <Grid item xs={4} className={classes.mainLeft} >
                            <img className={classes.img}
                                alt="Logo"
                                src="/images/logos/HestiaLogoWeb.svg"
                            />
                            <strong>
                            <div className={classes.text1}> Ainda não tem sua conta?</div>
                            </strong>
                            <Fab variant="extended" className={classes.text3} href="#contained-buttons" >        
                                Cadastre-se agora
                            </Fab>
                        </Grid> */}
                        {/* <Grid item xs={2} >
                        </Grid>    */}
                        
                        <Grid item xs={12} className={classes.mainRigth} >
                            
                            <div className={classes.text2}>                            
                                <strong> Bem Vindo! </strong>
                            </div>
                            <div className={classes.text4}>
                                Para acessar esse conteúdo, faça o login:
                            </div>

                            <Divider  style={{ maxWidth: "100%", marginBottom: 50, height: 3 }}></Divider>

                            <center>
                            <Grid item xs={10} >
                                <Field name="cpf" type="text" className={classes.loginInput} style={{ maxWidth: "100%", width: 250, marginTop:10 }} component={renderTextField} label="Login" {...cpfMask()}/>
                            </Grid>

                            <Grid item xs={10}>
                                <Field name="password" type="password" className={classes.passwordInput} style={{ maxWidth: "100%", width: 250, marginTop:10 }} component={renderTextField} label="Senha"/>
                            </Grid>

                            <Grid item xs={10}>
                                <Field name="companyId" component={renderSelectSearchField} label="Empresa" style={{maxWidth: "100%", width: 250, marginTop:10 }} {...{options: createList()}} />
                            </Grid>

                            <Grid item xs={10} style={{margin:10}}>
                              Cadastro no Módulo de Demadas <Link href="/regusuario" className={classes.forgotPassword}>Clique aqui!</Link>
                            </Grid>

                            <Grid item xs={10} style={{margin:10}}>
                              Cadastro no Módulo de Habitação <Link href="/minhahabitacao" className={classes.forgotPassword}>Clique aqui!</Link>
                            </Grid>

                            <Grid item xs={10} style={{margin:10}}>
                              Esqueceu sua senha? <Link href="/forgotPassword" className={classes.forgotPassword}>Clique aqui!</Link>
                            </Grid>

                            <br></br>


                            <ReCAPTCHA
                                sitekey="6LdVYg4pAAAAAJ6g0Zp7Bm8ofnngQx2vYN15UJJa"
                                onChange={onChange}
                                onExpired={onExpired}
                                className={classes.recaptchabox}
                            />
                            <Button disabled={submitting || !recaptched} type="submit" style={{ borderRadius: 20, width:130, maxWidth: "100%", marginTop:15 }} color="primary" variant="contained"> Login </Button>
                            </center>
                        </Grid>
                    </Grid>
                    
                </div>
            </form>
        )
    }
}

LoginForm = reduxForm({
    form: 'LoginForm',
    validate
})(LoginForm);

export default LoginForm;
