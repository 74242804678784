import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout, Procon as ProconLayout } from "./layouts";

import {
  Settings as SettingsView,
  FormUser,
  FormGrant,
  FormProfile,
  FormCompany,
  FormUserCompanyProfile,
  FormCandidato,
  FormConjuge,
  FormEndereco,
  FormFamilia,
  FormDemografico,
  FormDocumento,
  FormExame,
  FormResultado,
  FormSecretaria,
  FormAssunto,
  FormSla,
  FormDemanda,
  PublicFormCandidato,
  Success,
  ResetPasswordSuccess,
  FormConcorrencia,
  FormInscricoes,
  FormDemandaSimples,
  PublicFormRegUsers,
  PublicFormCracha,
  PublicFormCarteira,
  NotFound as NotFoundView,
  Forbidden as ForbiddenView,
  FormRegistroAtividade,
  FormEscalasAtividades,
  FormEscalasAtividadesIndividual,
  FormRegistroAtividadeCfg,
  FormRegistroAtividadeIndividual,
  FormRegistroAtividadesHistorico,
  FormGeolocalizacao,

  PublicProconAtendimento,
  FormProconAtendimento,
  FormProconDevice,
  FormProconAtendimentoReport,
  FormProconEstudante,
  FormProconResponsavel,
  FormProconProjeto
} from "./views";

import { UserList } from "./views/users/components/UserList";
import { GrantList } from "./views/grants/components/GrantList";
import { ProfileList } from "./views/profiles/components/ProfileList";
import { CompanyList } from "./views/companies/components/CompanyList";
import { UserCompanyProfileList } from "./views/userCompanyProfiles/components/UserCompanyProfileList";
import { CandidatoList } from "./views/candidatos/components/CandidatoList";
import { ExameList } from "./views/exames/components/ExameList";
import { ConcorrenciaList } from "./views/concorrencias/components/ConcorrenciaList";
import { InscricoesList } from "./views/inscricoes/components/InscricoesList";

import { SecretariasList } from "./views/secretarias/components/SecretariaList";
import { AssuntosList } from "./views/assuntos/components/AssuntosList";
import { SlasList } from "./views/slas/components/SlasList";
import { DemandasList } from "./views/demandas/components/DemandasList";
import { DemandaSimplesList } from "./views/demandaSimples/components/DemandaSimplesList";

import { EscalasAtividadesList } from "./views/escalasAtividades/components/EscalasAtividadesList";
import { EscalasAtividadesIndividualList } from "./views/escalasAtividadesIndividual/components/EscalasAtividadesIndividualList";
import { RegistroAtividadeCfgList } from "./views/registroAtividadesCfg/components/RegistroAtividadeCfgList";
import { RegistroAtividadeList } from "./views/registroAtividades/components/RegistroAtividadeList";
import { RegistroAtividadeIndividualList } from "./views/registroAtividadesIndividual/components/RegistroAtividadeIndividualList";
import { RegistroAtividadeHistoricoList } from "./views/registroAtividadesHistorico/components/RegistroAtividadeHistoricoList";

import { ProconAtendimentoList } from "./views/procon/components/ProconAtendimentoList";
import { ProconDeviceList } from "./views/procon/components/ProconDeviceList";
import { ProconProjetoList } from "./views/procon/projetos/ProconProjetoList";
import { ProconEstudanteList } from "./views/procon/projetos/estudantes/ProconEstudanteList";
import { ProconResponsavelList } from "./views/procon/projetos/responsaveis/ProconResponsavelList";

import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import LoginForm from "./components/LoginForm/LoginForm";
import ForgotPasswordForm from "./components/ForgotPasswordForm/ForgotPasswordForm";
import ResetPasswordForm from "./components/ResetPasswordForm/ResetPasswordForm";
import PublicProconVideoConference from "./views/procon/public/videoConference";
import PublicProconConclusaoAtendimento from "./views/procon/public/conclusaoAtendimento";
import ProconDeviceMap from "./views/procon/components/ProconDeviceMap";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />

      <RouteWithLayout
        component={FormResultado}
        exact
        layout={MinimalLayout}
        path="/meuexame"
      />

      <RouteWithLayout
        component={ResetPasswordForm}
        exact
        layout={MinimalLayout}
        path="/forgotPassword/:token"
      />

      <RouteWithLayout
        component={ResetPasswordSuccess}
        exact
        layout={MinimalLayout}
        path="/resetPassword/sucesso"
      />

      <RouteWithLayout
        component={PublicFormCandidato}
        exact
        layout={MinimalLayout}
        path="/minhahabitacao"
      />

      <RouteWithLayout
        component={PublicFormRegUsers}
        exact
        layout={MinimalLayout}
        path="/regusuario"
      />

      <RouteWithLayout
        component={PublicProconAtendimento}
        exact
        layout={ProconLayout}
        path="/public/proconjp/atendimento"
      />

      <RouteWithLayout
        component={PublicProconVideoConference}
        exact
        layout={ProconLayout}
        path="/public/proconjp/videoconference"
      />

      <RouteWithLayout
        component={PublicProconConclusaoAtendimento}
        exact
        layout={ProconLayout}
        path="/public/proconjp/conclusao"
      />

      <RouteWithLayout
        component={PublicFormCracha}
        exact
        layout={MinimalLayout}
        path="/crachavirtual/:id"
      />

      <RouteWithLayout
        component={PublicFormCarteira}
        exact
        layout={MinimalLayout}
        path="/carteiravirtual/:id"
      />

      <RouteWithLayout
        component={Success}
        exact
        layout={MinimalLayout}
        path="/minhahabitacao/sucesso"
      />

      <RouteWithLayout
        component={LoginForm}
        exact
        layout={MainLayout}
        path="/login"
      />

      <RouteWithLayout
        component={ForgotPasswordForm}
        exact
        layout={MainLayout}
        path="/forgotPassword"
      />

      <ProtectedRoute
        component={FormProconAtendimento}
        exact
        layout={MainLayout}
        path="/proconjp/atendimentos/new"
      />

      <ProtectedRoute
        component={FormProconAtendimento}
        exact
        layout={MainLayout}
        path="/proconjp/atendimentos/:id"
      />

      <ProtectedRoute
        component={ProconAtendimentoList}
        exact
        layout={MainLayout}
        path="/proconjp/atendimentos"
      />

      <ProtectedRoute
        component={FormProconDevice}
        exact
        layout={MainLayout}
        path="/proconjp/devices/new"
      />

      <ProtectedRoute
        component={FormProconDevice}
        exact
        layout={MainLayout}
        path="/proconjp/devices/:id"
      />

      <ProtectedRoute
        component={ProconDeviceList}
        exact
        layout={MainLayout}
        path="/proconjp/devices"
      />

      <ProtectedRoute
        component={ProconDeviceMap}
        exact
        layout={MainLayout}
        path="/proconjp/devicesMap"
      />

      <ProtectedRoute
        component={ProconEstudanteList}
        exact
        layout={MainLayout}
        path="/proconjp/estudantes"
      />

      <ProtectedRoute
        component={FormProconEstudante}
        exact
        layout={MainLayout}
        path="/proconjp/estudantes/new"
      />

      <ProtectedRoute
        component={FormProconEstudante}
        exact
        layout={MainLayout}
        path="/proconjp/estudantes/:id"
      />

      <ProtectedRoute
        component={ProconResponsavelList}
        exact
        layout={MainLayout}
        path="/proconjp/responsaveis"
      />

      <ProtectedRoute
        component={FormProconResponsavel}
        exact
        layout={MainLayout}
        path="/proconjp/responsaveis/new"
      />

      <ProtectedRoute
        component={FormProconResponsavel}
        exact
        layout={MainLayout}
        path="/proconjp/responsaveis/:id"
      />

      <ProtectedRoute
        component={ProconProjetoList}
        exact
        layout={MainLayout}
        path="/proconjp/projetos"
      />

      <ProtectedRoute
        component={FormProconProjeto}
        exact
        layout={MainLayout}
        path="/proconjp/projetos/new"
      />

      <ProtectedRoute
        component={FormProconProjeto}
        exact
        layout={MainLayout}
        path="/proconjp/projetos/:id"
      />

      <ProtectedRoute
        component={FormProconAtendimentoReport}
        exact
        layout={MainLayout}
        path="/reports"
      />

      <ProtectedRoute
        component={FormUser}
        exact
        layout={MainLayout}
        path="/users/new"
      />

      <ProtectedRoute
        component={FormUser}
        exact
        layout={MainLayout}
        path="/users/:id"
      />

      <ProtectedRoute
        component={UserList}
        exact
        layout={MainLayout}
        path="/users"
      />

      <ProtectedRoute
        component={FormEscalasAtividades}
        exact
        layout={MainLayout}
        path="/escalasAtividades/new"
      />

      <ProtectedRoute
        component={FormEscalasAtividades}
        exact
        layout={MainLayout}
        path="/escalasAtividades/:id"
      />

      <ProtectedRoute
        component={EscalasAtividadesList}
        exact
        layout={MainLayout}
        path="/escalasAtividades"
      />  


      <ProtectedRoute
        component={FormEscalasAtividadesIndividual}
        exact
        layout={MainLayout}
        path="/escalasAtividadesIndividual/:id"
      />

      <ProtectedRoute
        component={EscalasAtividadesIndividualList}
        exact
        layout={MainLayout}
        path="/escalasAtividadesIndividual"
      />    



      <ProtectedRoute
        component={FormRegistroAtividadeCfg}
        exact
        layout={MainLayout}
        path="/registroAtividadesCfg/new"
      />

      <ProtectedRoute
        component={FormRegistroAtividadeCfg}
        exact
        layout={MainLayout}
        path="/registroAtividadesCfg/:id"
      />

      <ProtectedRoute
        component={RegistroAtividadeCfgList}
        exact
        layout={MainLayout}
        path="/registroAtividadesCfg"
      />        


        

      <ProtectedRoute
        component={FormRegistroAtividade}
        exact
        layout={MainLayout}
        path="/registroAtividades/new"
      />

      <ProtectedRoute
        component={FormRegistroAtividade}
        exact
        layout={MainLayout}
        path="/registroAtividades/:id"
      />

      <ProtectedRoute
        component={RegistroAtividadeList}
        exact
        layout={MainLayout}
        path="/registroAtividades"
      />


      <ProtectedRoute
        component={FormRegistroAtividadesHistorico}
        exact
        layout={MainLayout}
        path="/registroAtividadesHistorico/new"
      />

      <ProtectedRoute
        component={FormRegistroAtividadesHistorico}
        exact
        layout={MainLayout}
        path="/registroAtividadesHistorico/:id"
      />

      <ProtectedRoute
        component={RegistroAtividadeHistoricoList}
        exact
        layout={MainLayout}
        path="/registroAtividadesHistorico"
      />


      <ProtectedRoute
        component={FormGeolocalizacao}
        exact
        layout={MainLayout}
        path="/geolocalizacao"
      />

      <ProtectedRoute
        component={FormRegistroAtividadeIndividual}
        exact
        layout={MainLayout}
        path="/registroAtividadesIndividual/new"
      />

      <ProtectedRoute
        component={FormRegistroAtividadeIndividual}
        exact
        layout={MainLayout}
        path="/registroAtividadesIndividual/:id"
      />

      <ProtectedRoute
        component={RegistroAtividadeIndividualList}
        exact
        layout={MainLayout}
        path="/registroAtividadesIndividual"
      />





      <ProtectedRoute
        component={FormGrant}
        exact
        layout={MainLayout}
        path="/grants/new"
      />

      <ProtectedRoute
        component={FormGrant}
        exact
        layout={MainLayout}
        path="/grants/:id"
      />

      <ProtectedRoute
        component={GrantList}
        exact
        layout={MainLayout}
        path="/grants"
      />

      <ProtectedRoute
        component={FormProfile}
        exact
        layout={MainLayout}
        path="/profiles/new"
      />

      <ProtectedRoute
        component={FormProfile}
        exact
        layout={MainLayout}
        path="/profiles/:id"
      />

      <ProtectedRoute
        component={ProfileList}
        exact
        layout={MainLayout}
        path="/profiles"
      />

      <ProtectedRoute
        component={FormUserCompanyProfile}
        exact
        layout={MainLayout}
        path="/user/company/profiles/new"
      />

      <ProtectedRoute
        component={FormUserCompanyProfile}
        exact
        layout={MainLayout}
        path="/user/company/profiles/:id"
      />

      <ProtectedRoute
        component={UserCompanyProfileList}
        exact
        layout={MainLayout}
        path="/user/company/profiles"
      />

      <ProtectedRoute
        component={FormCompany}
        exact
        layout={MainLayout}
        path="/companies/new"
      />

      <ProtectedRoute
        component={FormCompany}
        exact
        layout={MainLayout}
        path="/companies/:id"
      />

      <ProtectedRoute
        component={CompanyList}
        exact
        layout={MainLayout}
        path="/companies"
      />

      <ProtectedRoute
        component={FormCandidato}
        exact
        layout={MainLayout}
        path="/candidatos/new"
      />

      <ProtectedRoute
        component={FormCandidato}
        exact
        layout={MainLayout}
        path="/candidatos/:id"
      />

      <ProtectedRoute
        component={CandidatoList}
        exact
        layout={MainLayout}
        path="/candidatos"
      />

      <ProtectedRoute
        component={FormConcorrencia}
        exact
        layout={MainLayout}
        path="/concorrencias/new"
      />

      <ProtectedRoute
        component={FormConcorrencia}
        exact
        layout={MainLayout}
        path="/concorrencias/:id"
      />

      <ProtectedRoute
        component={ConcorrenciaList}
        exact
        layout={MainLayout}
        path="/concorrencias"
      />

      <ProtectedRoute
        component={FormInscricoes}
        exact
        layout={MainLayout}
        path="/inscricoes/new"
      />

      <ProtectedRoute
        component={FormInscricoes}
        exact
        layout={MainLayout}
        path="/inscricoes/:id"
      />

      <ProtectedRoute
        component={InscricoesList}
        exact
        layout={MainLayout}
        path="/inscricoes"
      />

      <ProtectedRoute
        component={FormConjuge}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/conjuge/new"
      />

      <ProtectedRoute
        component={FormConjuge}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/conjuge/:id"
      />

      <ProtectedRoute
        component={FormEndereco}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/endereco/new"
      />

      <ProtectedRoute
        component={FormEndereco}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/endereco/:id"
      />

      <ProtectedRoute
        component={FormFamilia}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/familia/new"
      />

      <ProtectedRoute
        component={FormFamilia}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/familia/:id"
      />

      <ProtectedRoute
        component={FormDemografico}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/demografico/new"
      />

      <ProtectedRoute
        component={FormDemografico}
        exact
        layout={MainLayout}
        path="/candidatos/:candidatoId/demografico/:id"
      />

      <ProtectedRoute
        component={FormDocumento}
        exact
        layout={MainLayout}
        path="/candidatos/:id/documentos"
      />

      <ProtectedRoute
        component={FormExame}
        exact
        layout={MainLayout}
        path="/exames/new"
      />

      <ProtectedRoute
        component={FormExame}
        exact
        layout={MainLayout}
        path="/exames/:id"
      />

      <ProtectedRoute
        component={ExameList}
        exact
        layout={MainLayout}
        path="/exames"
      />

      <ProtectedRoute
        component={FormSecretaria}
        exact
        layout={MainLayout}
        path="/secretarias/new"
      />

      <ProtectedRoute
        component={FormSecretaria}
        exact
        layout={MainLayout}
        path="/secretarias/:id"
      />

      <ProtectedRoute
        component={SecretariasList}
        exact
        layout={MainLayout}
        path="/secretarias"
      />

      <ProtectedRoute
        component={FormAssunto}
        exact
        layout={MainLayout}
        path="/assuntos/new"
      />

      <ProtectedRoute
        component={FormAssunto}
        exact
        layout={MainLayout}
        path="/assuntos/:id"
      />

      <ProtectedRoute
        component={AssuntosList}
        exact
        layout={MainLayout}
        path="/assuntos"
      />

      <ProtectedRoute
        component={FormSla}
        exact
        layout={MainLayout}
        path="/slas/new"
      />

      <ProtectedRoute
        component={FormSla}
        exact
        layout={MainLayout}
        path="/slas/:id"
      />

      <ProtectedRoute
        component={SlasList}
        exact
        layout={MainLayout}
        path="/slas"
      />

      <ProtectedRoute
        component={FormDemanda}
        exact
        layout={MainLayout}
        path="/demandas/new"
      />

      <ProtectedRoute
        component={FormDemanda}
        exact
        layout={MainLayout}
        path="/demandas/:id"
      />

      <ProtectedRoute
        component={DemandasList}
        exact
        layout={MainLayout}
        path="/demandas"
      />      

      <ProtectedRoute
        component={FormDemandaSimples}
        exact
        layout={MainLayout}
        path="/demandasSimples/new"
      />

      <ProtectedRoute
        component={FormDemandaSimples}
        exact
        layout={MainLayout}
        path="/demandasSimples/:id"
      />

      <ProtectedRoute
        component={DemandaSimplesList}
        exact
        layout={MainLayout}
        path="/demandasSimples"
      />

      <ProtectedRoute
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/home"
      />

      <RouteWithLayout
        component={ForbiddenView}
        exact
        layout={MinimalLayout}
        path="/forbidden"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
