import React from 'react'
import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import SelectHolder from 'react-select';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const renderHiddenHolder = (
  { input,  children, ...custom },
) => (
  <input type="hidden" {...input} {...custom}/>

);

export const renderLabelField = ({
  input,
  label,
  type,
  style,
  maxlength,
  meta: { touched, error },
  
}) => (
  <TextField
    variant="outlined" 
    type={type}
    style={style}
    label={label}
    error={touched && error}
    InputProps={{
      readOnly: true,
    }}
    {...input}
    helperText={touched && error ? error : null}
  />
)

export const renderSelectHolder = (
  { input, onChange, holders, children, ...custom },
) => (
  <SelectHolder placeholder='Selecione o servidor' options={holders} id='holder'  {...input} {...custom}/>
);

export const renderTextField = ({
    input,
    label,
    type,
    style,
    InputProps,
    disabled,
    required,
    meta: { touched, error },
    
  }) => (
    <TextField
      variant="outlined" 
      type={type}
      style={style}
      label={label}
      error={touched && error}
      InputProps={InputProps}
      InputLabelProps={{
        required: required
      }}
      {...input}
      disabled={disabled ? true : null}
      helperText={touched && error ? error : null}
    />
  )

  export const renderTextAreaField = ({
    input,
    label,
    type,
    style,
    rows,
    required,
    disabled,
    meta: { touched, error },
    
  }) => (
    <TextField
      type={type}
      style={style}
      variant="outlined" 
      multiline
      disabled={disabled}
      label={label}
      InputLabelProps={{
        required: required
      }}
      error={touched && error}
      rows={rows || 5}
      {...input}
      helperText={touched && error ? error : null}
    />
  )

export const renderCheckbox = ({ input, label, disabled }) => (
    <div>    
      <FormControlLabel
        variant="outlined"         
        disabled={disabled}
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
          />
        }
        label={label}
      />
    </div>
  )

export const renderFileUpload = ({ input, type, meta: { touched, error, warning } }) => {
  delete input.value

  return (
    <div>
      <label htmlFor={input.name}>
        <input {...input} type={type}/>
      </label>
    </div>
  )
}
  
export const renderDatePicker = ({
    input,
    label,
    defaultValue,
    style,
    type,
    required,
    meta: { touched, error },
    ...custom
  }) => (
    <TextField
    variant="outlined" 
      style={style}
      label={label}
      InputLabelProps={{
        required: required,
        shrink: true,
      }}
      type={type}
      error={touched && error}
      {...input}
      {...custom}
    />
    
  )

  export const renderSelectField = ({
    input,
    label,
    style,
    id,
    meta: { touched, error },
    children,
    required,
    ...custom
  }) => (
    <FormControl error={touched && error} style={{maxWidth:'100%'}} >
      <InputLabel shrink htmlFor="type" required={required}>{label}</InputLabel>
      <Select
        style={style}
        variant="outlined" 
        native
        {...input}
        {...custom}
        inputProps={{
          name: id,
          id: id
        }}
      >
        {children}
      </Select>
      <FormHelperText>{touched && error ? error : null}</FormHelperText>
    </FormControl>
  )

  export const renderSelectSearchField = ({
    input,
    label,
    style,
    id,
    meta: { touched, error },
    options,
    defaultValue,
    loading,
    required,
    ...custom
  }) => {
  return (
    (loading === undefined || loading) && <FormControl error={touched && error}>
      <Autocomplete
        style={style}
        {...custom}
        options={options}
        variant="outlined" 
        getOptionLabel={option => option.name}
        onChange={(event, newValue) => {
          if (newValue) {
            input.onChange(newValue.value);
          } else {
            input.onChange(undefined);
          }
        }}
        defaultValue={() => {
          const defaultOption = options.filter(v => v.value === defaultValue);
          return defaultOption && defaultOption[0];
        }}
        renderInput={params => (
          <TextField 
            {...params}
            label={label}
            InputLabelProps={{
              required: required
            }}
          />
        )}
      />
      <FormHelperText>{touched && error ? error : null}</FormHelperText>
    </FormControl>
  )}

  export const renderMultiSelectSearchField = ({
    input,
    label,
    style,
    id,
    meta: { touched, error },
    options,
    customChange,
    defaultValue,
    value,
    loading,
    required,
    ...custom
  }) => {

    return (
    (loading === undefined || loading) && <FormControl error={touched && error}>
      <Autocomplete
        style={style}
        {...custom}
        options={options}
        multiple        
        disableCloseOnSelect
        getOptionSelected={(option, value) => value.value === option.value}
        getOptionLabel={option => option.name}
        value={defaultValue}
        onChange={(_, selectedOptions) => {
          input.onChange(selectedOptions)
          if (customChange) {
            customChange(selectedOptions);
          }
        }}
        renderInput={params => (
          <TextField 
            variant="outlined" 
            {...params}
            label={label}
            InputLabelProps={{
              required: required
            }}
          />
        )}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </React.Fragment>
        )}
      />
      <FormHelperText>{touched && error ? error : null}</FormHelperText>
    </FormControl>
  )
  }
