import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import AuthState from '../../auth/Auth';

import { Password, Candidato, SadRegistro, Cracha } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Settings = () => {
  const classes = useStyles();

  const profile = AuthState.getProfileInfo();
 

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        wrap="wrap"
      >
        {((profile.name === "ADMIN") || (profile.name === "CANDIDATO"))  &&
        <Grid item xs={12} md={8} >
          <Candidato />
        </Grid>}

        {((profile.name === "ADMIN") || (profile.name === "CANDIDATO")) &&
        <Grid item xs={12} md={8} >
          <SadRegistro />
        </Grid>}

        {/* <Grid item xs={12} md={8} >
          <Photo />
        </Grid> */}

        <Grid item  xs={12} md={8}>
          <Password />
        </Grid>

        <Grid item  xs={12} md={8}>
          <Cracha />
        </Grid>        

      </Grid>
    </div>
  );
};

export default Settings;
