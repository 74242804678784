import React, {useState, useEffect} from 'react';
import { change } from 'redux-form';
import api from '../../services/api';
import { Card, CardHeader, CardContent, Divider, Grid, CardActions, Button } from '@material-ui/core';
import { useParams, useHistory } from "react-router-dom";
import Carousel from '../Carousel/Carousel';
import { ToastMessage } from '../../layouts';
import { uploadFile, remoteDeleteImage } from '../../services/images';
import { useSelector, useDispatch } from 'react-redux';
import CarouselActions from '../../store/ducks/Carousel';
import ReCAPTCHA from 'react-google-recaptcha';

let BasicForm = (props) => {
    const { handleSubmit, valid, reset, pristine, submitting } = props;
    const { id } = useParams();
    const history = useHistory();
    const [loadingEntity, setLoadingEntity] = useState(false);
    const [images, setImages] = useState([]);
    const [entity, setEntity] = useState(null);  
    const [recaptched, setRecaptched] = useState(false)  

    const dispatch = useDispatch();

    const formName = props.formName;
    const modelName = props.modelName;
    const label = props.label;
    const content = props.content;
    const imageContext = props.imageContext;
    const customAfterLoadEntity = props.customAfterLoadEntity;
    const keepUpdateAfterSubmit = props.keepUpdateAfterSubmit || false;
    const routeAfterSubmit = props.routeAfterSubmit;
    const customAfterInitialState = props.customAfterInitialState;
    const customAfterSubmit = props.customAfterSubmit;
    const loadImages = useSelector(state => state.carousel.loadImages);
    const needRecaptch = props.needRecaptch || false;
    const hiddenNewButton = props.hiddenNewButton || false;
    const hiddenSaveButton = props.hiddenSaveButton || false;
    const hiddenBackButton = props.hiddenBackButton || false;
    const submitLabel = props.submitLabel || 'Salvar';
    const maxImages = props.maxImages  || 5;
    const hiddenRemoveImgButton = props.hiddenRemoveImgButton || false;

    const afterLoadEntity = (entity) => {
        setImages(entity.images);
        setEntity(entity);
        if (customAfterLoadEntity) {
            customAfterLoadEntity(entity);
        }
    }

    const onChange = (value) => {
        setRecaptched(true);
    }

    const onExpired = (value) => {
        setRecaptched(false);
    }

    useEffect(() => {
        setLoadingEntity(false);
        
        if (loadImages){
            initialRoute();
            loadingData();
            dispatch(CarouselActions.setLoadImages(false));
        }
        
    }, [loadImages]);

    const afterInitialState = () => {
        setImages([])
        setEntity(null)
        if (customAfterInitialState) {
            customAfterInitialState();
        }
    }

    const afterSubmit = (values, createdEntity) => {
        if (!entity && images.length > 0) {
            api.doUploadImage(imageContext, createdEntity, images[0])
            .then(setImages([]))
        }
    }

    const initialState = () => {
        history.push(`/${modelName}`);
        afterInitialState();
    }

    const newState = () => {
        history.push(`/${modelName}/new`);
        afterInitialState();
    }    

    const updateFormProps = (result) => {
        const data = result.data;
        Object.keys(data).forEach((field) => {
            props.dispatch(change(formName, field, data[field]));
        });

        afterLoadEntity(data);
    }

    const loadingData = () => {
        return id && !entity;
    }

    const initialRoute = () => {
        if (!loadingEntity && id && id !== 'new') {
            setLoadingEntity(true);
            api.get(`${modelName}/${id}`).then(entity => {
                updateFormProps(entity);
                props.dispatch(change(formName, 'submit_type', 'update'));
            }).catch(err => {
            });
        }
    }

    const onSubmit = values => {
        if (props.customBeforeSubmit) {
            props.customBeforeSubmit(values);
        }
        if (values.submit_type === 'update') {
            return api.put(`${modelName}/${values.id}`, values)
                .then(res => {
                    ToastMessage.success(`Os dados do ${label} foram alterados com sucesso!`);
                    if (afterSubmit) {
                        afterSubmit(values, res.data);                        
                    }

                    if (customAfterSubmit) {
                        customAfterSubmit(res.data);
                    }

                    if (routeAfterSubmit) {
                        history.push(routeAfterSubmit.replace(":id", res.data.id));
                    } else {
                        initialState();
                        props.reset();
                    }
                })
                .catch((err) => {
                    ToastMessage.error(`Não foi possivel alterar os dados do ${label}!`);
                });

        } else {
            return api.post(`${modelName}`, values)
                .then((res) => {
                    ToastMessage.success(`${label} cadastrado com sucesso!`);
                    if (afterSubmit) {
                        afterSubmit(values, res.data);
                    }

                    if (routeAfterSubmit) {
                        history.push(routeAfterSubmit.replace(":id", res.data.id));
                    } else if (keepUpdateAfterSubmit) {
                        history.push(`/${modelName}/${res.data.id}`);
                    } else {
                        initialState();
                        props.reset();
                    }
                })
                .catch((err) => {
                    ToastMessage.error(`Não foi possivel cadastrar o ${label}!`);
                    for (let i = 0; 
                        err && err.response && err.response.data && err.response.data.errors && i < err.response.data.errors.length; 
                        i++) {
                        const error = err.response.data.errors[i];
                        ToastMessage.error(error.msg);
                    }
                })
        }
    }

    const deleteImage = async (image) => {
        if (entity) {
            await remoteDeleteImage(entity, image, image.context.replace(`/${image.filename}`))
            .then(newImages => setImages(newImages))
        } else {
            const newImages = images.filter((img) => img.context !== image.context);
            setImages(newImages);
        }
    }

    initialRoute();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card className='cardPersonalData'>
                <CardHeader title={`Dados do ${label}`} />
                <Divider />
                <CardContent>
                    {
                        loadingData() ? <div>Carregando...</div> : content
                    }
                    {entity && imageContext && <Carousel  
                        loading={!loadingEntity}
                        entity={entity}
                        images={images}
                        addCallback={uploadFile}
                        deleteCallback={deleteImage}
                        imageContext={imageContext}
                        maxImages={maxImages}
                        hiddenRemoveImgButton={hiddenRemoveImgButton}
                    />}

                </CardContent>

                <Divider />
            </Card>

            <Card>
                <CardActions style={{ margin: "1rem" }}>
                    <Grid container direction="row" justify="space-evenly" alignItems="center">
                        <Grid item xs={12} >
                        {needRecaptch &&
                        <center>
                        <ReCAPTCHA
                            sitekey="6LfqBaMaAAAAAF_GDt8y8EBxjZYIJiLfYsludp2j"
                            onChange={onChange}
                            onExpired={onExpired}
                            // className={classes.recaptchabox}
                        />
                        <br></br>
                        </center>
                        }
                        </Grid>

                        {!hiddenBackButton &&
                            <Button type="button" color="inherit" variant="contained" disabled={pristine} onClick={() => {history.goBack()}}>
                                Voltar
                            </Button>
                        }

                        {entity && !hiddenNewButton &&
                            <Button type="button" color="inherit" variant="contained" disabled={pristine} onClick={() => {
                                reset();
                                newState();
                            }}>
                                Novo
                            </Button>
                        }

                        {!hiddenSaveButton &&
                            <Button disabled={true} type="submit" color="primary" variant="contained" disabled={!valid || submitting || (!recaptched && needRecaptch)}>
                                {submitLabel}
                            </Button>
                        }
                    </Grid>
                </CardActions>
            </Card>
        </form>
    );
};

export default BasicForm;

