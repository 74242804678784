import React, {useState} from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { validCpf } from '../../../services';
import api from '../../../services/api';
import apiCEP from '../../../services/apiCEP';
import { Grid,  Avatar } from '@material-ui/core';
import { renderTextField, renderSelectField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import MyDivider from '../../../components/MyDivider';
import { cpfMask, phoneMask, cepMask } from '../../../services/masks';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import { useParams } from "react-router-dom";
import AuthState from '../../../auth/Auth';
import QRCode from "react-qr-code";
import Photo from './Photo';
import LOCAL_URL from '../../../services/qrCodeConfig';

const formName = 'User';
const modelName = 'users';
const label = 'Usuário';

// const useStyles = theme => ({
//     root: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         minHeight: 'fit-content'
//     },
//     avatar: {
//         width: 60,
//         height: 60
//     },
//     brQrCode:{
//         bgColor:'#09f3ad'
//     }
// });

const validate = (values, props) => {
    const errors = {}

    const requiredFields = [        
        'phone',
        'name',
        'cpf',
        'birthDate',
        'email',
        'valpassword',
        'confpassword',
    ]

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Campo Origatório'
        }
    })

    if ((values['valpassword'] !== '000000000') || (values['confpassword'] !== '000000000')) {
        if (values['valpassword'] !== values['confpassword']){
            errors['valpassword'] = 'Senha diferente';
        } else {
            props.dispatch(change(formName, 'password', values['valpassword']));
        }

    }


    if (
        values['email'] &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Endereço de Email invalido'
    }       
    
    if (values['cpf'] && !validCpf(values['cpf'])) {
        errors.cpf = 'CPF invalido';
    }


    return errors;
}

let Form = (props) => {
    const {reset} = props;
    const upper = value => value && value.toUpperCase()
    const lower = value => value && value.toLowerCase()
    const { id } = useParams();
    const [entity, setEntity] = useState({});
    const isAdmin = (AuthState.getProfileInfo() && AuthState.getProfileInfo().isAdmin) || false;
    const [user, setUser] = useState({});    
    // const [uploading, setUploading] = useState(false);
    const [photoUrl, setPhotoUrl] = useState("");
    


    

    // const { classes, rest, className } = props
    // const {user, profile, company} = state;

    const updateFormProps = (result) => {
        const data = result.data;
        Object.keys(data).forEach((field) => {
            props.dispatch(change(formName, field, data[field]));
        });
    }

    const requestCpf = (cpf) => {
        const isValid = validCpf(cpf);
        if (isValid) {
            api.get(`${modelName}/cpf/${cpf}`).then(entity => {
                updateFormProps(entity);
                props.dispatch(change(formName, 'submit_type', 'update'));
            }).catch(err => {
            });
        } else {
            reset();
        }
    }

    const clearOnEnterPassword = (event) => {        
        if ((event.target.value === '000000000') && (!id)){
            props.dispatch(change(formName, 'valpassword', ''));
            props.dispatch(change(formName, 'confpassword', ''));
        }    
    }

    const getDataByCEP = (event) => {

        let cep = event.target.value;

        apiCEP.get(`/ws/${cep}/json`).then(
            (data) => {
                props.dispatch(change(formName, 'address', data.data.logradouro.toUpperCase()));
                props.dispatch(change(formName, 'neighborhood', data.data.bairro.toUpperCase()));
                props.dispatch(change(formName, 'city', data.data.localidade.toUpperCase()));
                props.dispatch(change(formName, 'state', data.data.uf.toUpperCase()));
                props.dispatch(change(formName, 'complement', data.data.complemento.toUpperCase()));

            }
        ).catch(err => {
        });       
        
    };

    const afterLoadEntity = (entity) => {     
        AuthState.getUserInfo().then( (luser) => setUser(luser));
        getPhotoUrl(entity);
        setEntity(entity);
    }  

    const getPhotoUrl = (entity)  => {

        api.get(`/users/photourl/${entity.cpf}`)
        .then((response) => {
            const preSignedURL = response.data.url
            setPhotoUrl(preSignedURL);

        })
        .catch((error) => {
            console.error(error);            
            // ToastMessage.error('Problema ao atualizar Perfil');
        })
    }


    return (
        
        <BasicForm 
            hiddenNewButton={isAdmin?false:true} 
            name={'User'}
            customAfterLoadEntity={afterLoadEntity}
            routeAfterSubmit={isAdmin?'/users/':`/home`} 
            content={
                <Grid container spacing={3} wrap="wrap">
                    <Grid item xs={6} >
                        <Avatar
                            alt="Person"                            
                            style={{marginLeft:'1%', width: 130,height: 130, maxWidth: '100%',maxHeight: '100%'}}                            
                            src={photoUrl || "/images/avatars/generic-user.png"}
                        />                        
                    </Grid>

                    <Grid item xs={6} >                        
                        <QRCode
                            // alt="Person"                            
                            style={{marginLeft:'1%', width: 130,height: 130, maxWidth: '100%',maxHeight: '100%'}} 
                            bgColor='#f5f5f5'
                            fgColor='#000000'
                            size={130}
                            value={LOCAL_URL+"/crachavirtual/" + entity.id}
                        />                           
                    </Grid>

                    <Grid item xs={12} >
                        <Field name="cpf" {...{disabled: entity.id, required: true }} type="text" component={renderTextField} label="CPF" normalize={upper} style={{ maxWidth: '100%', width: 300 }} maxLength={11} {...cpfMask(requestCpf)} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="name" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="rg" type="text" component={renderTextField} label="Doc." normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="phone" type="text" component={renderTextField} label="Telefone" normalize={upper} style={{ maxWidth: '100%', width: 300 }} maxLength={9} {...phoneMask} />
                    </Grid>
                    
                    <Grid item xs={6}>
                        <Field name="email" type="email" component={renderTextField} label="Email" normalize={lower} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                    <InputLabel>Data de Nasc.</InputLabel>
                        <Field name="birthDate" type="date" component={renderTextField} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="type" type="text" component={renderTextField} label="Tipo" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="valpassword" onFocus={clearOnEnterPassword} type="password" component={renderTextField} label="Senha" style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="confpassword" type="password" component={renderTextField} label="Senha Confirmação" style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>                    

                    <Grid item xs={6}>
                        <Field disabled={isAdmin?false:true} label="Secretaria" name="secretaria" component={renderSelectField}  style={{maxWidth: '100%', width: 300}} >
                            <option value={'Nenhuma'}>Nenhuma</option>
                            <option value={'Controladoria Municipal'}>Controladoria Municipal</option>
                            <option value={'Instituto Cândida Vargas'}>Instituto Cândida Vargas</option>
                            <option value={'FUNJOP'}>FUNJOP</option>
                            <option value={'Instituto de Previdência do Município'}>Instituto de Previdência do Município</option>
                            <option value={'Gabinete do Prefeito'}>Gabinete do Prefeito</option>
                            <option value={'Gabinete do Vice-Prefeito'}>Gabinete do Vice-Prefeito</option>
                            <option value={'Sec. Gestão Governamental'}>Sec. Gestão Governamental</option>
                            <option value={'Sec. Administração'}>Sec. Administração</option>
                            <option value={'Sec. Saúde'}>Sec. Saúde</option>
                            <option value={'Sec. Educação'}>Sec. Educação</option>
                            <option value={'Sec. Planejamento'}>Sec. Planejamento</option>
                            <option value={'Sec. Fazenda'}>Sec. Fazenda</option>
                            <option value={'Sec. Desenv. Social'}>Sec. Desenv. Social</option>
                            <option value={'Sec. Habitação'}>Sec. Habitação</option>
                            <option value={'Sec. Comunicação'}>Sec. Comunicação</option>
                            <option value={'Sec. Direitos Humanos'}>Sec. Direitos Humanos</option>
                            <option value={'Sec. Proteção e Defesa do Consumidor'}>Sec. Proteção e Defesa do Consumidor</option>
                            <option value={'Sec. Infra Estrutura'}>Sec. Infra Estrutura</option>
                            <option value={'Sec. Trabalho, Produção e Renda'}>Sec. Trabalho, Produção e Renda</option>
                            <option value={'Sec. Juventude Esporte e Recreação'}>Sec. Juventude Esporte e Recreação</option>
                            <option value={'Sec. Turismo'}>Sec. Turismo</option>
                            <option value={'Sec. Politicas Públicas das Mulheres'}>Sec. Politicas Públicas das Mulheres</option>
                            <option value={'Sec. Desenv. Urbano'}>Sec. Desenv. Urbano</option>
                            <option value={'Sec. Ciência e Tecnologia'}>Sec. Ciência e Tecnologia</option>
                            <option value={'Sec. Meio Ambiente'}>Sec. Meio Ambiente</option>
                            <option value={'Sec. Segurança Urbana e Cidadania'}>Sec. Segurança Urbana e Cidadania</option>
                            <option value={'Sec. Defesa Civil'}>Sec. Defesa Civil</option>
                            <option value={'Sec. Mobilidade Urbana'}>Sec. Mobilidade Urbana</option>
                            <option value={'Procuradoria Municipal'}>Procuradoria Municipal</option>
                        </Field>
                    </Grid>  

                    <Grid item xs={6}>
                        <Field disabled={isAdmin?false:true} label="Cargo" name="cargo" component={renderSelectField}  style={{maxWidth: '100%', width: 300}} >                            
                            <option value={'Servidor'}>Servidor</option>
                            <option value={'Agente'}>Agente</option>
                            <option value={'Coordenador'}>Coordenador</option>
                            <option value={'Diretor'}>Diretor</option>
                            <option value={'Assessor'}>Assessor</option>
                            <option value={'Professor'}>Professor</option>
                            <option value={'Secretário'}>Secretário</option>
                            <option value={'Prefeito'}>Prefeito</option>
                            <option value={'Vice-Prefeito'}>Vice-Prefeito</option>
                        </Field>
                    </Grid>  

                    <Grid item xs={6}>
                        <Field  disabled={isAdmin?false:true} id="status" name="status" component={renderSelectField} label="Status" style={{ maxWidth: '100%', width: 300 }}>
                            <option value="" />
                            <option value={'A'}>ATIVO</option>
                            <option value={'I'}>INATIVO</option>
                            <option value={'P'}>PENDENTE</option>
                        </Field>
                    </Grid>                    

                    <Grid item xs={12}>
                        <MyDivider/>                      
                    </Grid>                    

                    <Grid item xs={12}>
                        <Field name="cep" type="text" onBlur={getDataByCEP} component={renderTextField} label="CEP" normalize={upper} style={{ maxWidth: '100%', width: 200 }} maxLength={8} {...cepMask} />
                    </Grid>                

                    <Grid item xs={6}>
                        <Field name="address" type="text" component={renderTextField} label="Endereço" normalize={upper} style={{ maxWidth: '100%', width: 300}} />
                    </Grid>


                    <Grid item xs={6}>
                        <Field name="number" type="number" component={renderTextField} label="Número" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="neighborhood" type="text" component={renderTextField} label="Bairro" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="city" type="text" component={renderTextField} label="Cidade" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel>Estado</InputLabel>
                        <Field id="state" name="state" component={renderSelectField} style={{ maxWidth: '100%', width: 300 }}>
                            <option ></option>
                            <option value={'AC'}>Acre</option>
                            <option value={'AL'}>Alagoas</option>
                            <option value={'AP'}>Amapá</option>
                            <option value={'AM'}>Amazonas</option>
                            <option value={'BA'}>Bahia</option>
                            <option value={'CE'}>Ceará</option>
                            <option value={'DF'}>Distrito Federal</option>
                            <option value={'ES'}>Espírito Santo</option>
                            <option value={'GO'}>Goiás</option>
                            <option value={'MA'}>Maranhão</option>
                            <option value={'MT'}>Mato Grosso</option>
                            <option value={'MS'}>Mato Grosso do Sul</option>
                            <option value={'MG'}>Minas Gerais</option>
                            <option value={'PA'}>Pará</option>
                            <option value={'PB'}>Paraíba</option>
                            <option value={'PR'}>Paraná</option>
                            <option value={'PE'}>Pernambuco</option>
                            <option value={'PI'}>Piauí</option>
                            <option value={'RJ'}>Rio de Janeiro</option>
                            <option value={'RN'}>Rio Grande do Norte</option>
                            <option value={'RS'}>Rio Grande do Sul</option>
                            <option value={'RO'}>Rondônia</option>
                            <option value={'RR'}>Roraima</option>
                            <option value={'SC'}>Santa Catarina</option>
                            <option value={'SP'}>São Paulo</option>
                            <option value={'SE'}>Sergipe</option>
                            <option value={'TO'}>Tocantins</option>
                        </Field>
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="complement" type="text" component={renderTextField} label="Complemento" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Photo user={entity} />
                    </Grid>
                    

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues: {
        'status':'A',
        'birthDate': moment().format('YYYY-MM-DD'),
        'valpassword':  '000000000',
        'confpassword': '000000000',
        
    }
})(Form);

export default Form;

// export default withStyles(useStyles)(Profile)

