import React, {useState} from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { validate } from '../../../services';
import { Grid , Button} from '@material-ui/core';
import { renderSelectField  } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import api from '../../../services/api';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { isMobile } from 'react-device-detect';
import Icon from '../../../components/icon/icons';
import { RegistrosUsersList } from './RegistrosUsersList';
import FormRegistroIndividualActions from '../../../store/ducks/FormRegistroIndividual';
import { useSelector ,useDispatch } from 'react-redux';
// import CustomMarker  from './CustomMarker';


const formName = 'Geolocalizacao';
const modelName = 'geolocalizacao';
const label = 'Geolocalizacao';


let Form = (props) => {
    const [entity, setEntity] = useState({});
    const [loadingSecretaria, setLoadingSecretaria] = useState(false);
    const [secretariaList, setSecretariaList] = useState([]); 
    const [userList, setUserList] = useState(props.userList || []);
    const [loadingUser, setLoadingUser] = useState(false);  
    const dispatch = useDispatch();   
    const selectedUserId = useSelector(state => state.formRegistroIndividual.comentario);

    const [loadingEscalaAtividades, setLoadingEscalaAtividades] = useState(false);
    const [escalaAtividadesList, setEscalaAtividadesList] = useState([]); 
    
    const [position, setPosition] = useState({});
    const [nome, setNome] = useState('');
    
    const [positionEmpresa, setPositionEmpresa] = useState({});
    const [nomeEmpresa, setNomeEmpresa] = useState('PMJP');

    const [center, setCenter] = useState({ lat: -7.111588, lng: -34.850261 });

    const [selectedSecretariaId, setSelectedSecretariaId] = useState('all');
    const [selectedEscalasAtividadeId, setSelectedEscalasAtividadeId] = useState('all');
    const [selectedOwnerId, setSelectedOwnerId] = useState('all');
    
    const [selectedUserList, setSelectedUser] = useState([]);
    const [registroAtividadesList, setRegistroAtividades] = useState([]);
    // const [selectedUserId, setSelectedUserId] = useState('94e33ad0-494a-11ec-99fc-2b524d3a45be');

    const CustomMarker = (props) => {
        const {id} = props;
    
        const onMarkerClick = (evt) => {            
            
            dispatch(FormRegistroIndividualActions.setComentario(id));
            console.log(id);
        };
    
        return (
            <Marker
                onClick={onMarkerClick}
                {...props}
            />
        );
    };    

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={12}
            center={center}
            defaultCenter={{ lat: -7.111588, lng: -34.850261 }}
        >
            {selectedUserList &&
                selectedUserList.map((mark) =>(
                    <CustomMarker 
                    icon={{url:'/images/MarkerPerson.png' }} 
                    id={mark.id} 
                    // onClick={markerClick}
                    // label={nomeEmpresa.split(' ')[0]}                     
                    position={{lat: parseFloat(mark.latitude), lng: parseFloat(mark.longitude)}} 
                    title={mark.name.split(' ')[0]}
                    // position={{lat: -7.111588, lng: -34.850261}} 
                    {...props}
                    />
                ))
            }

            {secretariaList &&
                secretariaList.map((mark) =>(
                    <Marker 
                    icon={{url:'/images/MarkerEmpresa.png' }} 
                    key={mark.id} 
                    // label={nomeEmpresa.split(' ')[0]}                     
                    position={{lat: parseFloat(mark.latitudeMin), lng: parseFloat(mark.longetudeMin)}} 
                    title={mark.nome.split(' ')[0]}
                    // position={{lat: -7.111588, lng: -34.850261}} 
                    
                    />
                ))
            }


        </GoogleMap>
    ))    
    
    const getEscalaAtividadesList = () => {

        if (!loadingEscalaAtividades) {
            setLoadingEscalaAtividades(true);
            api.get('/escalasAtividades?pageSize=-1').then(list => {
                setEscalaAtividadesList(list.data.rows);                
            });
        }
    }   

    const getUserList = () => {
        if (!loadingUser) {
            setLoadingUser(true);
            api.get('/users?pageSize=-1').then(list => {
                setUserList(list.data.rows);
            });
        }
    }    

    const afterLoadEntity = (entity) => {
        setEntity(entity);

        setPositionEmpresa({lat: parseFloat(entity.secretaria.latitudeMin) , lng: parseFloat(entity.secretaria.longetudeMin)});
        setNomeEmpresa(entity.secretaria.nome);
        setNome(entity.users.name);                
        setPosition({lat: parseFloat(entity.latitude), lng: parseFloat(entity.longitude)});
        setCenter({lat: parseFloat(entity.latitude), lng: parseFloat(entity.longitude)});        
    }

    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option value={'all'} key={'all'}>Todos</option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.titulo||entity.nome||entity.name||entity.descricao}</option>);
            })
            return (finalList);
        }
    }  

    const getSecretariasList = () => {

        if (!loadingSecretaria) {
            setLoadingSecretaria(true);
            api.get('/secretarias?pageSize=-1').then(list => {
                setSecretariaList(list.data.rows);                
            });
        }
    }      

    const getRegistroAtividadesUser = () =>{
        api.get(`/registroAtividades/lastLocation/users?secretariaId=${selectedSecretariaId}&escalasAtividadeId=${selectedEscalasAtividadeId}&ownerId=${selectedOwnerId}`)
                    .then(list => { 
                        console.log(list.data);
                        let result = [];
                        let lastUserId =  '';

                        list.data.forEach((item) =>{
                            if (item.id !== lastUserId){
                                result.push(item)    
                            } 
                            lastUserId = item.id;
                        }

                        )

                        console.log(result);
                        setSelectedUser(result) 
                    }
        );

    }
    
    getSecretariasList();    
    getUserList(); 
    getEscalaAtividadesList();   

    const handleGeoLocation = (e) => {
        navigator.geolocation.getCurrentPosition(success, error, options); 
      }  
    
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      
      function success(pos) {
        var crd = pos.coords;

        props.dispatch(change(formName, 'latitude', crd.latitude));
        props.dispatch(change(formName, 'longitude', crd.longitude));

        // console.log('Sua posição atual é:');
        // console.log('Latitude : ' + crd.latitude);
        // console.log('Longitude: ' + crd.longitude);
        // console.log('Mais ou menos ' + crd.accuracy + ' metros.');
      };
      
      function error(err) {
        console.warn('ERROR(' + err.code + '): ' + err.message);
      };  

    const changeSecretariaId = (e) => {        
        if (e.target.value) setSelectedSecretariaId(e.target.value)
    } 

    const changescalasAtividadeId = (e) => {
        if (e.target.value) setSelectedEscalasAtividadeId(e.target.value)
    } 

    const changeUserId = (e) => {
        if (e.target.value) setSelectedOwnerId(e.target.value)
    }         

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}            
            keepUpdateAfterSubmit={true}
            hiddenSaveButton={true}
            hiddenBackButton={true}
            // customAfterInitialState={afterInitialState}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={isMobile?12:4}>
                        <Field 
                            name="secretariaId" 
                            component={renderSelectField} 
                            onChange={changeSecretariaId} 
                            label="Unidade de Lotação" 
                            style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(secretariaList)}
                        </Field>
                    </Grid>  

                    <Grid item xs={isMobile?12:4}>
                        <Field 
                            name="escalasAtividadeId" 
                            component={renderSelectField} 
                            label="Escalas de Atividades" 
                            onChange={changescalasAtividadeId} 
                            style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(escalaAtividadesList)}
                        </Field>
                    </Grid>    

                    <Grid item xs={isMobile?12:4}>
                        <Field 
                            name="ownerId" 
                            component={renderSelectField} 
                            label="Colaboerador" 
                            onChange={changeUserId} 
                            style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(userList)}
                        </Field>
                    </Grid>     

                    <Grid item xs={12}>
                        <Button 
                            style={{ width: isMobile?400:280, maxWidth:'100%', height:40 }} 
                            onClick={getRegistroAtividadesUser} 
                            color="secondary" 
                            variant="outlined"
                            endIcon={ <Icon icon='search' size='24' color="blue"/>   }
                            >                                                    
                            Pesquisar                                       
                        </Button>  
                    </Grid>                                                       


                    <Grid item xs={12}>
                        <div >
    
                        <MyMapComponent
                            isMarkerShown
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmiTdTowI7DxNIodiBXI0d6JkCqOuGwA0&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{maxWidth: '100%', width: 1000 , height: `100%` }} />}
                            containerElement={<div style={{maxWidth: '100%', width: 1000 , height: `100%` }} />}
                            center={position}                            
                            mapElement={<div style={{ height: `400px`}} />}
                        />
                        </div>  
                    </Grid> 

                    {(selectedUserId && selectedUserId!=='') &&
                    <Grid item  container xs={12} spacing={2} wrap="wrap" >
                        <RegistrosUsersList  id={selectedUserId} style={{  maxWidth:'100%'}}/>
                    </Grid>                                           
                    }

      
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{
        status:'P'
    }
})(Form);

export default Form;

