import React from 'react';
import { isMobile } from 'react-device-detect';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import CardList from '../../../components/CardList/CardList';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const ConcorrenciaList = (props) => {
    const columns = [
        //{ name: 'id', title: '#' },
        { name: 'name', title: 'Nome', filterable: true, primary: true },
        { name: 'editalLink', title: 'Link' },
        { name: 'number', title: 'Número', filterable: true, secondary: true },
        { name: 'editalText', title: 'Texto', content: true}
    ]

    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid>
                <Grid item>
                    <Card className='cardPersonalData'>
                    <CardHeader title="Concorrências" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ?
                                <CardList route={"concorrencias"} endpoint={"concorrencias"} columns={columns}/> :
                                <GridList modelName={"concorrencias"} 
                                    editModelRoute={'/concorrencias'}
                                    newModelRoute={'/concorrencias/new'}
                                    deleteCallback={deleteCallback}
                                    deleteCallbackFailure={deleteCallbackFailure}
                                    endpoint={'concorrencias'}
                                    enableActions={true}
                                    columns={columns}/>
                            }
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

