import React from 'react';
import { isMobile } from 'react-device-detect';
import CardList from '../../../components/CardList/CardList';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const UserCompanyProfileList = (props) => {
    const columns = [
        //{ name: 'id', title: '#' },
        { name: 'cpf', getCellValue: row => row.user ? row.user.cpf : null, title: 'CPF', filterable: true, secondary: true },
        { name: 'name', getCellValue: row => row.user ? row.user.name : null, title: 'Nome', filterable: true, primary: true },
        { name: 'status', title: 'Situação' },
        { name: 'profile', getCellValue: row => row.profile ? row.profile.name : null, title: 'Perfil' },
        { name: 'company', getCellValue: row => row.company ? row.company.name : null, title: 'Empresa', filterable: true, content: true}
    ]

    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro excluído com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid>
                <Grid item>
                    <Card className='cardPersonalData'>
                    <CardHeader title="Perfis dos Usuários" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ?
                            <CardList route={"user/company/profiles"} endpoint={"user/company/profiles"} columns={columns}/> :
                            <GridList modelName={"Perfil do Usuário"} 
                                editModelRoute={'/user/company/profiles'}
                                newModelRoute={'/user/company/profiles/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'user/company/profiles'}
                                enableActions={true}
                                columns={columns}/>}
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

