import React from 'react';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Typography from '@material-ui/core/Typography';

import './rating.css';
import { IconButton } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';

const RatingButton = (props) => {
    const {label, component, color, value, additionalEvent} = props;
    const [active, setActive] = useState(false);

    return (
        <div>
            <IconButton 
                id={`rating-button-${value}`}
                style={{width: '100%', color: active && color}}
                className={`rating-component-${value}`}
                onClick={() => {
                    additionalEvent && additionalEvent(value);
                    setActive(!active);
                }}
            >
                {component}
            </IconButton>
            <Typography style={{textAlign: 'center'}}>{label}</Typography>
        </div>
    )
}

const RadioComponent = (props) => {
    const {customOnClick} = props;
    const fontSize = 96;

    const ratings = {
        1: {
            component: <SentimentVeryDissatisfiedIcon style={{fontSize: fontSize}}/>,
            label: 'Muito ruim',
            color: '#FF0D0D'
        },
        2: {
            component: <SentimentDissatisfiedIcon style={{fontSize: fontSize}}/>,
            label: 'Ruim',
            color: '#FF4E11'
        },
        3: {
            component: <SentimentSatisfiedIcon style={{fontSize: fontSize}}/>,
            label: 'Regular',
            color: 'orange'
        },
        4: {
            component: <SentimentSatisfiedAltIcon style={{fontSize: fontSize}}/>,
            label: 'Bom',
            color: '#3BCA6D'
        },
        5: {
            component: <SentimentVerySatisfiedIcon style={{fontSize: fontSize}}/>,
            label: 'Muito bom',
            color: '#3BCA6D'
        },
    }

    const buttonList = [];

    const [lastRating, setLastRating] = useState('');
    const [rating, setRating] = useState(0);

    const disableLast = (value) => {
        if (!value) return;
        const buttons = document.getElementsByTagName('button');
        for (var i = 0; i < buttons.length; i++) {
            const button = buttons[i]
            if (button.classList.contains(`rating-component-${value}`)) {
                button.click();
            }
        }
    }

    const selectRating = (value) => {
        disableLast(lastRating);
        setRating(value);
        if (lastRating === value) setRating(0);
        setLastRating(value);
        customOnClick && customOnClick(value);
    }

    useEffect(() => {
        buildButtonList();
    }, []);

    const buildButtonList = () => {
        Object.keys(ratings).forEach(key => {
            buttonList.push(
                <RatingButton 
                    key={key}
                    value={key}
                    label={ratings[key].label}
                    component={ratings[key].component}
                    color={ratings[key].color}
                    additionalEvent={selectRating}
                />
            )
        })
    }

    buildButtonList()

    return (
        <div style={{display: 'flex', justifyContent: 'space-between', padding: 60}} >
            {buttonList}
        </div>
    )
}

export default RadioComponent;