import React from 'react';
import { Grid, CardContent, CardHeader, Card, CardActionArea, CardMedia, Typography } from '@material-ui/core';

const ServicoForm = (props) => {
    const onSelect = props.onSelect;

    return (
        <>
            <CardHeader title={
                <Typography gutterBottom variant="h3" component="h2">
                    Escolha um Serviço:
                </Typography> } 
                />
            <CardContent style={{width: '100%'}}>
                <Grid container spacing={2} wrap="wrap">
                    <Grid item xs={4}>
                        <Card onClick={() => {
                            onSelect && onSelect('procon');
                        }}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    image="/images/procon/Illus_Procon.svg"
                                    style={{height:450, maxHeight: 450, padding: 15}}
                                    alt="procon"
                                />
                                <CardContent>
                                <Typography gutterBottom variant="h1" component="div">
                                    Falar com o Procon-JP
                                </Typography>
                                <Typography variant="h4">
                                    Entre em uma videochamada com um de nossos atendentes
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={4}>
                        <Card>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    image="/images/procon/QrCodeProcon.png"
                                    style={{height:450, maxHeight: 450, padding: 15}}
                                    alt="procon"
                                />
                                <CardContent>
                                <Typography gutterBottom variant="h1" component="div">
                                    Atendimento WhatsApp
                                </Typography>
                                <Typography variant="h4">
                                    Atendimento online das 8:00 as 18:00
                                    para atendimentos fora desse horário
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={4}>
                        <Card>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    image="/images/procon/Illus_Docs_Desativado.svg"
                                    style={{height:4500, maxHeight: 450, padding: 15}}
                                    alt="procon"
                                />
                                <CardContent>
                                <Typography gutterBottom variant="h1" component="div">
                                    Consulte a Legislação
                                </Typography>
                                <Typography variant="h4">
                                    Acesse nossa biblioteca de Leis municipais e estaduais
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </>
    )
}

export default ServicoForm;
