import React from 'react';
import CardList from '../../../components/CardList/CardList';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const RegistroAtividadeHistoricoList = (props) => {
    const columns = [                                    
        { name: 'dataRequisicao', title: 'Data', width: 110, primary: true },
        { name: 'horaRegistro', title: 'Hora', width: 110, secondary: true },
        { name: '$secretaria.nome$', title: 'Unidade de Lotação', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 200 , filterable: true},
        { name: '$escalasAtividades.descricao$', title: 'Escala', getCellValue: row => row.escalasAtividades ? row.escalasAtividades.descricao : null, width: 200, content: true},
    ]

    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Registro de Atividade excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid>
                <Grid item>
                    <Card className='cardPersonalData'>
                    <CardHeader title="Registro Histórico" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ?
                            <CardList route={"registroAtividadesHistorico"} endpoint={"registroAtividadesHistorico"} columns={columns} hideDeleteButton={true}/> :
                            <GridList modelName={"Registro Histórico"} 
                                editModelRoute={'/registroAtividadesHistorico'}
                                newModelRoute={'/registroAtividadesHistorico/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'registroAtividadesHistorico'}
                                disableNewButtom={true}
                                enableActions={true}
                                hiddenDeleteButton={true}
                                columns={columns}/>}
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

