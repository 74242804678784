import React, { useEffect, useState } from 'react';
import Filter from './Filter';
import ContentList from './ContentList';
import { CircularProgress, Divider } from '@material-ui/core';
import { api } from '../../services';
import InfiniteScroll from 'react-infinite-scroll-component';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({
    divider: {
        marginTop: 5
    },
    container: {
        width: "100%"        
    },
    loadingContainer: {
        position: "relative",
        height: 50
    }
}))

const CardList = ({columns, route, endpoint, hideAddButton, hideEditButton, hideDeleteButton, getImage}) => {
    const classes = useStyles();

    const pageSize = 20
    const [page, setPage] = useState(0)
    const [query, setQuery] = useState("")
    const [rows, setRows] = useState([])
    const [lastFetchSize, setLastFetchSize] = useState(0)

    useEffect(() => {
        fetchPage(query, page)
    }, [query, page])

    const fetchPage = (query, page) => {
        const pageUrl = `page=${page}&pageSize=${pageSize}&`
        api.get(`/${endpoint}?${pageUrl}&${query}`)
        .then((response) => {
            let newRows = []
            if (page !== 0) {
                rows.forEach((row) => newRows.push(row))
            }
            response.data.rows.forEach((row) => newRows.push(row))
            setRows(newRows)
            setLastFetchSize(response.data.rows.length)
        })
    }

    const fetchMoreData = () => {
        setPage(page + 1)
    };

    const removeItem = (id) => {
        let newRows = rows.filter((row) => row.id !== id)

        setRows(newRows)
    }

    return <div className={classes.container}>
        <Filter fields={columns} query={setQuery} route={route} hideAddsButton={hideAddButton}/>
        <Divider className={classes.divider}/>

        <InfiniteScroll
          style={{overflow: false}}
          dataLength={rows.length}
          next={fetchMoreData}
          hasMore={lastFetchSize > 0}
          loader={
            <div className={classes.loadingContainer}>
                {page > 0 && <CircularProgress className="loading-icon-mui"/>}
            </div>
          }
        >
          <ContentList 
            rows={rows} 
            columns={columns} 
            route={route} 
            endpoint={endpoint} 
            onRemoveItem={removeItem} 
            hideAddButton={hideAddButton} 
            hideEditButton={hideEditButton} 
            hideDeleteButton={hideDeleteButton}            
            />
        </InfiniteScroll>
    </div>
}

export default CardList;