import React, { useState } from "react";
import { List, ListItem } from "@material-ui/core";
import { Edit, Delete, Add } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { api } from "../../services";
import { confirmAlert } from 'react-confirm-alert';

const useStyles = makeStyles(theme => ({
    actionItem: {
        float: "left",
        height: 24,
        textAlign: "center",
        color: theme.palette.action.active,
        marginLeft: 3
    },
    listItem: {
        display:'flex',
        justifyContent:'flex-end'
    }
}))

const CardActions = ({endpoint, route, row, deleteCallback, deleteCallbackFailure, onRemoveItem, hideAddButton, hideEditButton, hideDeleteButton}) => {
    const classes = useStyles();
    const history = useHistory();
    const [deleting, setDeleting] = useState(false)

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const clickDelete = () => {
        confirmAlert({
            title: 'Confirma exclusão',
            message: `Confirma exclusão do ${capitalize(route)}: ${row.name ? row.name : row.id}?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setDeleting(true);
                        api.delete(`/${endpoint}/${row.id}`)
                            .then(response => {
                                deleteCallback && deleteCallback(response);
                                onRemoveItem && onRemoveItem(row.id)
                            })
                            .catch(error => {
                                deleteCallbackFailure && deleteCallbackFailure(error);
                            })
                            .finally(() => setDeleting(false));
                    }
                },
                {
                    label: 'Não',
                    onClick: () => console.log(`ignoring exclusion ${row.id}`)
                }
            ]
        });
    }

    return <List>
        {!hideAddButton && <ListItem button className={classes.listItem} onClick={() => {history.push(`/${route}/new`);}}>
            <div>
                <div className={classes.actionItem}>Novo</div>
                <div className={classes.actionItem}><Add size={24} /></div>
            </div>
        </ListItem>}
        {!hideEditButton && <ListItem button className={classes.listItem} onClick={() => {history.push(`/${route}/${row.id}`);}}>
            <div>
                <div className={classes.actionItem}>Alterar</div>
                <div className={classes.actionItem}><Edit size={24} /></div>
            </div>
        </ListItem>}
        {!hideDeleteButton && <ListItem button className={classes.listItem} onClick={clickDelete} disabled={deleting}>
            <div>
                <div className={classes.actionItem}>Excluir</div>
                <div className={classes.actionItem}><Delete size={24} /></div>
            </div>
        </ListItem>}
    </List>
}

export default CardActions;