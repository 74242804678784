import React, { useState } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { api } from '../../../../services';
import { Grid, Divider, CardContent, CardHeader, Card, Button } from '@material-ui/core';
import { renderTextField, renderSelectField } from '../../../../components/Fields/Fields';
import BasicForm from '../../../../components/BasicForm';
import { FamiliaList } from './FamiliaList';
import { moneyMask } from '../../../../services/masks';
import { ToastMessage } from '../../../../layouts';
import Icon from '../../../../components/icon/icons';

import FormTabs from '../../FormTabs';
import { useSelector , useDispatch} from 'react-redux';
import FormCandidatoActions from '../../../../store/ducks/FormCandidato';
import '../../required-field.css';
import AuthState from '../../../../auth/Auth';

const formName = 'Demográfico';
const modelName = 'demograficos';
const label = 'Demografico';

const isRequired = () => {
    return {required: true}
}

const validate = (values) => {
    const errors = {}

    const requiredFields = [
        'idade',
        'quantidadetrabalhando',
        'rendafamiliar'
    ]

    if (values.quantidadetrabalhando && (values.quantidadetrabalhando < 0 || values.quantidadetrabalhando > 99)) {
        errors.quantidadetrabalhando = 'Número de pessoas que trabalham inválido: intervalo (0 a 99)';
    }

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Campo Origatório'
        }
    })

    return errors;
}

let Form = (props) => {
    const dispatch = useDispatch();
    
    const [nomeParente, setNomeParente] = useState('');
    const [idadeParente, setIdadeParente] = useState('');
    const [parentescoParente, setParentescoParente] = useState('');
    const [escolaridadeParente, setEscolaridadeParente] = useState(0);

    const formCandidatoData = useSelector(state => state.formCandidato.data);
    const [showFamilia, setShowFamilia] = useState(formCandidatoData.demograficoId !== undefined); 
    const isAdmin = (AuthState.getProfileInfo() && AuthState.getProfileInfo().isAdmin) || false; 
    const [entity, setEntity] = useState({});  
    
    const upper = value => value && value.toUpperCase()

    dispatch(FormCandidatoActions.setFormName({formName:formName}));

    const beforeSubmit = (values) => {
        values.candidatoId = formCandidatoData.id;
    }

    const addParente = (e) => {
        e.preventDefault();

        const body = {
            nomeParente,
            idadeParente,
            parentescoParente,
            escolaridadeParente,
            demograficoId: formCandidatoData.demograficoId
        }


        api.post(`demograficos/${formCandidatoData.demograficoId}/familias`, body)
        .then(response => {
            console.log(response)
            props.dispatch(change(formName, 'nomeparente', ''));
            props.dispatch(change(formName, 'idadeparente', ''));
            props.dispatch(change(formName, 'parentescoparente', ''));
            props.dispatch(change(formName, 'escolariadeparente', ''));
            setShowFamilia(false);
            setShowFamilia(true);
            ToastMessage.success('Membro adicionado à família');
        })
        .catch(error => {
            ToastMessage.warning('Problemas ao inserir dado familiar');
        })
    }

    const changeNomeParente = (e) => {
        if (e.target.value) setNomeParente(e.target.value.toUpperCase())
    }

    const changeIdadeParente = (e) => {
        if (e.target.value) setIdadeParente(e.target.value.toUpperCase())
    }

    const changeParentescoParente = (e) => {
        if (e.target.value) setParentescoParente(e.target.value.toUpperCase())
    }

    const changeEscolaridadeParente = (e) => {
        if (e.target.value) setEscolaridadeParente(e.target.value)
    }

    const afterLoadEntity = (entity) => {
        setEntity(entity);
        if (entity){
            setShowFamilia(true)       
        }
    }

    return (
        

        <>
            <FormTabs/>

            <BasicForm
                routeAfterSubmit={`/candidatos/${formCandidatoData.id}/demografico/:id`}
                customBeforeSubmit={beforeSubmit}
                customAfterLoadEntity={afterLoadEntity}
                hiddenNewButton={true}  
                content={
                    <Grid container wrap="wrap" className="row">
                        <Card className="personalData" style={{width: '100%'}}>
                            <CardHeader title="Dados do Beneficiário"/>
                            <CardContent>
                                <Grid container spacing={2} wrap="wrap">
                                    <Grid item xs={4}>
                                        <Field name="idade" type="text" component={renderTextField} label="Idade" style={{ width: 250, maxWidth:'100%' }} {...isRequired()}/>
                                    </Grid>
                                    {isAdmin && 
                                    <>
                                        <Grid item xs={4}>
                                            <Field name="quadra" type="text" component={renderTextField} label="Quadra" style={{ width: 250, maxWidth:'100%' }} normalize={upper}/>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Field name="bloco" type="text" component={renderTextField} label="Bloco" style={{ width: 250, maxWidth:'100%' }} normalize={upper}/>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Field name="apto" type="text" component={renderTextField} label="Apartamento" style={{ width: 350, maxWidth:'100%' }} normalize={upper}/>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Field label="Tipo de Moradia" name="tipomoradia" component={renderSelectField} style={{ width: 350, maxWidth:'100%'}}>
                                                <option></option>
                                                <option value={'CASA'}>CASA</option>
                                                <option value={'APARTAMENTO'}>APARTAMENTO</option>
                                            </Field>
                                        </Grid>
                                
                                    </>
                        }

                                </Grid>
                            </CardContent>
                        </Card>

                        <Divider />

                        {showFamilia && formCandidatoData.demograficoId && 
                        // {true && 
                            <>
                                <Card className='cardPersonalData'>
                                    <CardHeader title="Composição familiar"/>
                                    <CardContent>
                                        <Grid item  container xs={12} spacing={2} wrap="wrap" >
                                            <FamiliaList  style={{  maxWidth:'100%'}}/>
                                        </Grid>

                                        <Grid container spacing={2} wrap="wrap">
                                            <Grid item xs={6}>
                                                <Field 
                                                    name="nomeparente"
                                                    type="text"
                                                    component={renderTextField}
                                                    label="Nome parente"
                                                    style={{ width: 350, maxWidth:'100%' }}
                                                    normalize={upper}
                                                    onChange={changeNomeParente}
                                                />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Field
                                                    name="idadeparente"
                                                    type="text"
                                                    component={renderTextField}
                                                    label="Idade parente"
                                                    style={{ width: 250 , maxWidth:'100%' }}
                                                    normalize={upper}
                                                    onChange={changeIdadeParente}
                                                />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Field 
                                                    name="parentescoparente"
                                                    type="text"
                                                    component={renderTextField}
                                                    label="Parentesco"
                                                    style={{ width: 250, maxWidth:'100%'  }}
                                                    normalize={upper}
                                                    onChange={changeParentescoParente}
                                                />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Field
                                                    name="escolaridadeparente"
                                                    type="text"
                                                    component={renderSelectField}
                                                    label="Escolaridade"
                                                    style={{ width: 350 , maxWidth:'100%'}}
                                                    normalize={upper}
                                                    onChange={changeEscolaridadeParente}
                                                >
                                                    <option value={0}>Alfabetizado</option>
                                                    <option value={1}>Ensino Fundamental Completo</option>
                                                    <option value={2}>Ensino Fundamental Incompleto</option>
                                                    <option value={3}>Ensino Medio Completo</option>
                                                    <option value={4}>Ensino Medio Incompleto</option>
                                                    <option value={5}>Ensino Superior Completo</option>
                                                    <option value={6}>Ensino Superior Incompleto</option>
                                                    <option value={7}>Não Alfabetizado</option>
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12}>      
                                                

                                                <Button 
                                                    style={{ width: 350, maxWidth:'100%', height:40 }} 
                                                    onClick={addParente} 
                                                    color="secondary" 
                                                    variant="outlined"
                                                    endIcon={ <Icon icon='person_add_alt_1' size='24' color="blue"/>   }
                                                    >                                                    
                                                    Adicionar                                       
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                
                                <Divider />
                            </>
                        }

                        <Card className='cardPersonalData' style={{width: '100%'}}>
                            <CardHeader title="Saúde"/>
                            <CardContent>
                                <Grid container spacing={2} wrap="wrap">
                                    <Grid item xs={2}>
                                        <Field label="Gestante" name="gestante" component={renderSelectField} style={{ width: 150, maxWidth:'100%' }}>
                                            <option value={'NÃO'}>NÃO</option>
                                            <option value={'SIM'}>SIM</option>                                            
                                        </Field>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Field name="gestantequantidade" type="text" component={renderTextField} label="Quantas" style={{ width: 150, maxWidth:'100%' }} normalize={upper}/>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Field label="Diabéticos" name="diabetico" component={renderSelectField} style={{ width: 150, maxWidth:'100%' }}>
                                            <option value={'NÃO'}>NÃO</option>
                                            <option value={'SIM'}>SIM</option>                                            
                                        </Field>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Field name="diabeticoquantidade" type="text" component={renderTextField} label="Quantos" style={{ width: 150, maxWidth:'100%' }} normalize={upper}/>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Field label="Hipertensos" name="hipertenso" component={renderSelectField} style={{ width: 150, maxWidth:'100%' }}>
                                            <option value={'NÃO'}>NÃO</option>
                                            <option value={'SIM'}>SIM</option>                                            
                                        </Field>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Field name="hipertensoquantidade" type="text" component={renderTextField} label="Quantos" style={{ width: 150, maxWidth:'100%' }} normalize={upper}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Divider />
                        <Card className='cardPersonalData' style={{width: '100%'}}>
                            <CardHeader title="Emprego e Renda"/>
                            <CardContent>
                                <Grid container spacing={2} wrap="wrap">
                                    <Grid item xs={3}>
                                        <Field name="quantidadetrabalhando" type="number" component={renderTextField} label="Número de pessoas que trabalham" style={{ width: 150, maxWidth:'100%' }} normalize={upper} {...isRequired()}/>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Field label="Tipo" name="tipotrabalho" component={renderSelectField} style={{ width: 150, maxWidth:'100%' }}>
                                            <option value={'FORMAL'}>FORMAL</option>
                                            <option value={'INFORMAL'}>INFORMAL</option>
                                        </Field>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Field name="rendafamiliar" type="text" component={renderTextField} label="Renda Familiar" style={{ width: 150, maxWidth:'100%' }} normalize={upper} {...moneyMask} {...isRequired()}/>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Field label="Possui algum comércio" name="possuicomercio" component={renderSelectField} style={{ width: 150, maxWidth:'100%' }}>
                                            <option value={'NÃO'}>NÃO</option>
                                            <option value={'SIM'}>SIM</option>                                            
                                        </Field>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} wrap="wrap">
                                    <Grid item xs={4}>
                                        <Field name="nomecomercio" type="text" component={renderTextField} label="Qual" style={{ width: 250, maxWidth:'100%' }} normalize={upper}/>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Field name="nomeresponsavel" type="text" component={renderTextField} label="Nome do responável" style={{ width: 250, maxWidth:'100%' }} normalize={upper}/>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Field name="localfuncionamento" type="text" component={renderTextField} label="Onde funciona" style={{ width: 250, maxWidth:'100%' }} normalize={upper}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Divider />
                        <Card className='cardPersonalData' style={{width: '100%'}}>
                            <CardHeader title="Transporte"/>
                            <CardContent>
                                <Grid container spacing={1} wrap="wrap">
                                    <Grid item xs={3}>
                                        <Field label="Possui veículo" name="possuiveiculo" component={renderSelectField} style={{ width: 150, maxWidth:'100%' }}>
                                            <option value={'NÃO'}>NÃO</option>
                                            <option value={'SIM'}>SIM</option>                                            
                                        </Field>
                                    </Grid>

                                    <Grid item xs={9}>
                                        <Field name="tipoveiculo" type="text" component={renderTextField} label="Tipo" style={{ width: 250, maxWidth:'100%' }} normalize={upper}/>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                formName={formName}
                modelName={modelName}
                label={label}
                { ...props }
            />
        </>
    );
};

Form = reduxForm({
    form: formName,
    validate
})(Form);

export default Form;

