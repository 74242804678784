import React from 'react';
import { isMobile } from 'react-device-detect';
import CardList from '../../../components/CardList/CardList';
import {GridList} from '../../../components/GridList/GridList';
import { Card, CardHeader, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ToastsStore } from 'react-toasts';

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
    }
  }));
  
export const RegistroAtividadeList = (props) => {
    const columns = [
        { name: '$users.name$', title: 'Nome', getCellValue: row => row.users ? row.users.name : null, width: 200, filterable: true, primary: true },
        { name: 'dataRequisicao', title: 'Data', width: 120 },
        { name: '$secretaria.nome$', title: 'Unidade de Lotação', getCellValue: row => row.secretaria ? row.secretaria.nome : null, width: 200, filterable: true, secondary: true },
        { name: '$escalasAtividades.descricao$', title: 'Escala', getCellValue: row => row.escalasAtividades ? row.escalasAtividades.descricao : null, width: 200, filterable: true, content: true },
    ]

    const classes = useStyles();

    const deleteCallback = (reponse) => {
        ToastsStore.success('Secretaria excluída com sucesso.');
    }

    const deleteCallbackFailure = (response) => {

    }

    return (
        <div className={classes.root}>
            <Grid>
                <Grid item>
                    <Card className='cardPersonalData'>
                    <CardHeader title="Registro Geral" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3} wrap="wrap">
                            {isMobile ?
                            <CardList route={"registroAtividades"} endpoint={"registroAtividades"} columns={columns}/> :
                            <GridList modelName={"Registro Geral"} 
                                editModelRoute={'/registroAtividades'}
                                newModelRoute={'/registroAtividades/new'}
                                deleteCallback={deleteCallback}
                                deleteCallbackFailure={deleteCallbackFailure}
                                endpoint={'registroAtividades'}
                                enableActions={true}
                                columns={columns}/>}
                        </Grid>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

